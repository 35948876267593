import {RegistrationField} from "./registration-filed";

export const AGE_CATEGORY_FORM_FIELD = {
  name: RegistrationField.AGE_CATEGORY,
  setting: {
    title: `Возрастная категория`,
    isRequired: false,
    isSaveDataAfterRegistration: true,
    placeholder: ``,
    ifElse: ``,
  }
};
