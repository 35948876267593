import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import NominationParsing from "./parsing";

export default class NominationModel {
  constructor({nominationId, title = ``, parentId = ``, topId = `0`, subs = [], method = ``, maxCountPlayer = Infinity}) {
    this.nominationId = nominationId;
    this.title = title;
    this.parentId = parentId;
    this.topId = topId;
    this.subs = subs;
    this.method = method;
    this.maxCountPlayer = maxCountPlayer;
  }

  static parse(data) {
    const nominationIds = Object.keys(data);
    if (isEmptyArray(nominationIds)) {
      return null;
    }
    const nominationId = nominationIds[0];
    const method = NominationParsing.method(data[nominationId]);
    const maxCountPlayer = NominationParsing.documentMaxPeople(data[nominationId]);

    return new NominationModel({nominationId, method, maxCountPlayer});
  }

  // для вложенное иерархии
  static parseSubNomination(data, parentId = `0`, topId = `0`) {
    const keys = Object.keys(data);

    return keys
    .filter((key) => key !== `title`)
    .map((key) => {
      const nominationId = key;
      const title = data[key].title;
      const subTopId = topId === `0` ? nominationId : topId;
      const subs = NominationModel.parseSubNomination(data[key], nominationId, subTopId);
      // const subs = NominationModel.parseSubNomination(data[key], nominationId, topId);
      const method = data[key].nomination_rating_method;

      return new NominationModel({nominationId, title, parentId, topId: parentId === `0` ? topId : subTopId, subs, method});
    });
  }
}
