/* библиотека NameToRequest для преобразования переменной в текст для запроса */

export const NTR = {
  ACTION: `action`,
  ADDRESS: `address`,
  AGE_CATEGORY_ID: `age_category_id`,
  AGE_CATEGORY_TITLE: `age_category_title`,
  ARCHIVE: `archive`,
  ALERT_ID: `alert_id`,
  ALL: `all`,
  AMOUNT_PAID: `amount_paid`,
  AMOUNT_TO_PAY: `amount_to_pay`,
  AUTH: `auth`,
  AUTH_LINK: `auth_link`,
  AUTHOR_TYPE: `author_type`,

  CARD_ID: `card_id`,
  CANCEL: `cancel`,
  CHAIN: `chain`,
  CHAT_ID: `chat_id`,
  COLOR: `color`,
  COMPERE: `compere`,
  CONSTANT: `constant`,
  CONTEST: `contest`,
  CONTEST_DOCUMENT: `contest_document`,
  CONTEST_ID: `contest_id`,
  CONTEST_NEW_ID: `contest_new_id`,
  CRITERIA_ID_BASE: `criteria_id_base`,
  CRITERIA_WEIGHT: `criteria_weight`,
  COMPERE_ID: `compere_id`,
  CONTEST_ADDRESS: `contest_address`,
  CONTEST_CITY: `contest_city`,
  CONTEST_ORGANIZATION: `contest_organization`,
  CONTEST_ROLE: `contest_role`,
  CONTEST_SERVICE_PRICE: `contest_service_price`,
  CONTEST_STITLE: `contest_stitle`,
  CONTEST_TARIFF: `contest_tariff`,
  CONTEST_TITLE: `contest_title`,
  CONTEST_TITLE_SEARCH: `contest_title_search`,
  CRITERIA: `criteria`,
  CRITERIA_ID: `criteria_id`,
  CRITERIA_MAX_POINTS: `criteria_max_points`,
  CRITERIA_NOMINATION: `criteria_nomination`,
  CRITERIA_NOTE: `criteria_note`,
  CRITERIA_TITLE: `criteria_title`,
  CURRENT_PAGE: `current_page`,

  DATA: `data`,
  DATE: `date`,
  DEADLINE_AFFIRMATION: `deadline_affirmation`,
  DEADLINE_PAYMENT: `deadline_payment`,
  DELETE: `delete`,
  DEMO: `demo`,
  DIRECTOR: `director`,
  DIRECTOR_ID: `director_id`,
  DIRECTOR_TITLE: `director_title`,
  DOCUMENT: `document`,
  DOCUMENT_CHECK: `document_check`,
  DOCUMENT_ID: `document_id`,
  DOCUMENT_MAX_PEOPLE: `document_max_people`,
  DOCUMENT_PRINTED: `document_printed`,
  DOCUMENT_TITLE: `document_title`,
  DOB: `dob`,
  DOWN: `down`,
  DURATION: `duration`,

  EMAIL: `email`,
  EMPTY: ``,
  ERROR: `error`,
  ERROR_INFO: `error_info`,
  ERROR_TYPE: `error_type`,
  EVENT: `event`,


  FIELD_ID: `field_id`,
  FIELD_DATA: `field_data`,
  FILE_DELETE: `file_delete`,
  FILE_DELETE_PHONOGRAM: `file_delete_phonogram`,
  FILE_ID: `file_id`,
  FILE_TYPE: `file_type`,
  FILTER: `filter`,
  FINISH_DATE: `finish_date`,
  FORCE: `force`,

  GET_DATA: `get_data`,
  GET_PERFORMANCE_QUALIFICATION: `get_performance_qualification`,
  GET_TABLE: `get_table`,
  GOOGLE_CITY: `google_city`,

  GROUP: `group`,
  GROUP_ID: `group_id`,
  GROUP_TURN_ID: `group_turn_id`,
  GROUP_TIME_START: `group_time_start`,
  GROUP_DURATION: `group_duration`,
  GROUP_JOIN: `group_join`,
  GROUP_PRIZE: `group_prize`,
  GROUP_PRIZE_MANUAL: `group_prize_manual`,
  GROUP_RATING_SUM: `group_rating_sum`,

  ID: `id`,
  ID_TARGET: `id_target`,

  LANDMARK_ID: `landmark_id`,
  LANDMARK_START: `landmark_start`,
  LANDMARK_TITLE: `landmark_title`,
  LANGUAGE: `language`,
  LASTNAME: `lastname`,
  LINK: `link`,
  LINK_ID: `link_id`,
  LOGIN: `login`,

  MAIL: `mail`,
  MAIL_ID: `mail_id`,
  MAIL_SERVER: `mail_server`,
  MAIL_TEMPLATE: `mail_template`,
  MAIL_TEMPLATE_ID: `mail_template_id`,
  MARGINS: `margins`,
  MASTER: `master`,
  MESSAGE: `message`,
  MESSAGE_ID: `message_id`,
  MESSAGE_TEXT: `message_text`,
  METHOD: `method`,
  MIDDLENAME: `middlename`,
  MISSED: `missed`,
  MONEY_STATUS: `money_status`,
  MONEY_SYSTEM: `money_system`,
  MONEY_PARAM_1: `money_param_1`,
  MONEY_PARAM_2: `money_param_2`,
  MONEY_PARAM_3: `money_param_3`,
  MONEY_ROLE: `money_role`,

  NAME: `name`,
  NAME_TEMPLATE: `name_template`,
  NEW_EMAIL: `new_email`,
  NEW_ID: `new_id`,
  NEW_PHONE_NUMBER: `new_phone_number`,
  NEWS_ID: `news_id`,
  NO_ACCESS: `no_access`,
  NO_ALERT: `no alert`,
  NO_MESSAGE: `no message`,
  NOMINATION_ID: `nomination_id`,
  NOMINATION_TITLE: `nomination_title`,
  NOMINATION_PARENT_ID: `nomination_parent_id`,
  NOMINATION_RATING_METHOD: `nomination_rating_method`,
  NOT_REQUIRED: `not required`,
  NOTE: `note`,
  NOTE_ID: `note_id`,
  NOW: `now`,
  NULL: `0`,

  ONE: `1`,
  ORDER_BY: `order_by`,
  ORGANIZATION_ALIAS: `organization_alias`,
  ORGANIZATION_ID: `organization_id`,
  ORGANIZATION_CITY: `organization_city`,
  ORGANIZATION_CITY_PRINT: `organization_city_print`,
  ORGANIZATION_GOOGLE_CITY: `organization_google_city`,
  ORGANIZATION_TITLE: `organization_title`,
  ORIENT: `orient`,

  PAGE: `page`,
  PAGE_ID: `page_id`,
  PAGE_SETTINGS: `page_settings`,
  PAGE_SETTINGS_ID: `page_settings_id`,
  PASS: `pass`,
  PASSWORD: `password`,
  PAYER: `payer`,
  PAYER_TITLE: `payer_title`,
  PAYMENT_LINK: `payment_link`,
  PAYMENT_ROLE: `payment_role`,
  PAYMENT_SYSTEM: `payment_system`,

  PAUSE: `pause`,

  PENALTY: `penalty`,

  PEOPLE: `people`,
  PEOPLE_ALIAS: `people_alias`,
  PEOPLE_CARD: `people_card`,
  PEOPLE_CRITERIA: `people_criteria`,
  PEOPLE_DIRECTOR: `people_director`,
  PEOPLE_DOB: `people_dob`,
  PEOPLE_EMAIL: `people_email`,
  PEOPLE_ID: `people_id`,
  PEOPLE_MY_ACTION: `people_my_action`,
  PEOPLE_MY_ALERT: `people_my_alert`,
  PEOPLE_MY_NAME: `people_my_name`,
  PEOPLE_NAME: `people_name`,
  PEOPLE_NAME_TYPE: `people_name_type`,
  PEOPLE_NEWS: `people_news`,
  PEOPLE_PASS: `people_pass`,
  PEOPLE_PHONE_NUMBER: `people_phone_number`,
  PEOPLE_TRANSACTION: `people_transaction`,

  PERFORMANCE_ADD_PEOPLE: `performance_add_people`,
  PERFORMANCE_AGE_CATEGORY: `performance_age_category`,
  PERFORMANCE_AGE_RANGE: `performance_age_range`,
  PERFORMANCE_ARCHIVE: `performance_archive`,
  PERFORMANCE_CID: `performance_cid`,
  PERFORMANCE_CITY: `performance_city`,
  PERFORMANCE_CITY_FULL: `performance_city_full`,
  PERFORMANCE_CITY_ID: `performance_city_id`,
  PERFORMANCE_CITY_TYPE: `performance_city_type`,
  PERFORMANCE_CITY_STYPE: `performance_city_stype`,
  PERFORMANCE_CONTACT: `performance_contact`,
  PERFORMANCE_CONTENT: `performance_content`,
  PERFORMANCE_CONTEST: `performance_contest`,
  PERFORMANCE_COUNT: `performance_count`,
  PERFORMANCE_COUNTRY: `performance_country`,
  PERFORMANCE_CREATION_DATE: `performance_creation_date`,
  PERFORMANCE_DIRECTOR: `performance_director`,
  PERFORMANCE_DURATION_SUM: `performance_duration_sum`,
  PERFORMANCE_ENTRY: `performance_entry`,
  PERFORMANCE_ID: `performance_id`,
  PERFORMANCE_GROUP: `performance_group`,
  PERFORMANCE_LINK: `performance_link`,
  PERFORMANCE_MONEY: `performance_money`,
  PERFORMANCE_NOMINATION: `performance_nomination`,
  PERFORMANCE_NOTE: `performance_note`,
  PERFORMANCE_PHONOGRAM_STATUS: `performance_phonogram_status`,
  PERFORMANCE_PAUSE: `performance_pause`,
  PERFORMANCE_PLAYER: `performance_player`,
  PERFORMANCE_PLAYER_COUNT: `performance_player_count`,
  PERFORMANCE_PRIZE: `performance_prize`,
  PERFORMANCE_PRIZE_MANUAL: `performance_prize_manual`,
  PERFORMANCE_QUALIFICATION: `performance_qualification`,
  PERFORMANCE_ORGANIZATION_CITY: `performance_organization_city`,
  PERFORMANCE_ORGANIZATION_CITY_TYPE: `performance_organization_city_type`,
  PERFORMANCE_ORGANIZATION_CITY_STYPE: `performance_organization_city_stype`,
  PERFORMANCE_ORGANIZATION_ID: `performance_organization_id`,
  PERFORMANCE_ORGANIZATION_TITLE: `performance_organization_title`,
  PERFORMANCE_RATING: `performance_rating`,
  PERFORMANCE_RATING_SUM: `performance_rating_sum`,
  PERFORMANCE_REAL_TIME: `performance_real_time`,
  PERFORMANCE_REFEREE: `performance_referee`,
  PERFORMANCE_TEAM_CITY: `performance_team_city`,
  PERFORMANCE_TEAM_CITY_TYPE: `performance_team_city_type`,
  PERFORMANCE_TEAM_CITY_STYPE: `performance_team_city_stype`,
  PERFORMANCE_TEAM_ID: `performance_team_id`,
  PERFORMANCE_TEAM_TITLE: `performance_team_title`,
  PERFORMANCE_TIME_AVAIL: `performance_time_avail`,
  PERFORMANCE_TIME_FULL: `performance_time_full`,
  PERFORMANCE_TIME_START: `performance_time_start`,
  PERFORMANCE_TITLE: `performance_title`,
  PERFORMANCE_TURN_ID: `performance_turn_id`,
  PERFORMANCE_SOURCE: `performance_source`,
  PERFORMANCE_USER_DATA: `performance_user_data`,

  PHONE_NUMBER: `phone_number`,

  PHONOGRAM_DATE: `phonogram_date`,
  PHONOGRAM_DURATION: `phonogram_duration`,
  PHONOGRAM_ID: `phonogram_id`,
  PHONOGRAM_LINK: `phonogram_link`,
  PHONOGRAM_SIZE: `phonogram_size`,
  PHONOGRAM_START_WITH_POINT: `phonogram_start_with_point`,
  PHONOGRAM_STATUS: `phonogram_status`,
  PHONOGRAM_TITLE: `phonogram_title`,

  PLAY: `play`,

  PLAYER: `player`,
  PLAYER_COUNT: `player_count`,
  PLAYER_DIPLOM: `player_diplom`,
  PLAYER_ID: `player_id`,
  PLAYER_PEOPLE_ID: `player_people_id`,
  PLAYERS_COUNT_NAME_SHOW: `player_count_name_show`,

  PREPARE: `prepare`,

  PRIZE: `prize`,
  PRIZE_COUNT: `prize_count`,
  PRIZE_COUNT_USE: `prize_count_use`,
  PRIZE_ID: `prize_id`,
  PRIZE_MANUAL: `prize_manual`,
  PRIZE_OPTION: `prize_option`,
  PRIZE_RATING: `prize_rating`,
  PRIZE_TITLE: `prize_title`,

  RATING: `rating`,
  RATING_METHOD: `rating_method`,
  RATING_SUM: `rating_sum`,

  READ: `read`,
  RECCURENT: `recurrent`,

  REFEREE: `referee`,
  REFEREE_AUTH_LINK: `referee_auth_link`,
  REFEREE_CRITERIA: `referee_criteria`,
  REFEREE_ID: `referee_id`,
  REFEREE_PERFORMANCE_CRITERIA: `referee_performance_criteria`,
  REFEREE_PERFORMANCE_NEXT: `referee_performance_next`,
  REFEREE_PERFORMANCE_NOW: `referee_performance_now`,
  REFEREE_PERFORMANCE_RATING_SUM: `referee_performance_rating_sum`,

  RESET: `reset`,
  RESPONSE: `response`,
  REQUEST: `request`,
  ROLE: `role`,
  RULES_LINK: `rules_link`,

  QUALIFICATION_ID: `qualification_id`,
  QUALIFICATION_TITLE: `qualification_title`,

  SEARCH: `search`,

  SEND_EMAIL: `send_email`,

  SET_ALERT_READ: `set_alert_read`,
  SET_ALIAS: `set_alias`,

  SET_CONTEST_ADDRESS: `set_contest_address`,
  SET_CONTEST_AGE_CATEGORY: `set_contest_age_category`,
  SET_CONTEST_COMPERE: `set_contest_compere`,
  SET_CONTEST_COPY: `set_contest_copy`,
  SET_CONTEST_CITY: `set_contest_city`,
  SET_CONTEST_CRITERIA: `set_contest_criteria`,
  SET_CONTEST_DATE: `set_contest_date`,
  SET_CONTEST_LANDMARK: `set_contest_landmark`,
  SET_CONTEST_LEAD: `set_contest_lead`,
  SET_CONTEST_MONEY_SETTINGS: `set_contest_money_settings`,
  SET_CONTEST_MONITOR: `set_contest_monitor`,
  SET_CONTEST_NOMINATION: `set_contest_nomination`,
  SET_CONTEST_PRIZE: `set_contest_prize`,
  SET_CONTEST_RATING: `set_contest_rating`,
  SET_CONTEST_REFEREE: `set_contest_referee`,
  SET_CONTEST_ROLE: `set_contest_role`,
  SET_CONTEST_RULES_LINK: `set_contest_rules_link`,
  SET_CONTEST_TARIFF: `set_contest_tariff`,
  SET_CONTEST_TERMINAL: `set_contest_terminal`,
  SET_CONTEST_TITLE: `set_contest_title`,
  SET_CONTEST_TURN: `set_contest_turn`,
  SET_CONTEST_SMTP: `set_contest_smtp`,
  SET_CONTEST_SOUNDMAN: `set_contest_soundman`,
  SET_CREATE_ARCHIVE: `set_create_archive`,

  SET_CURRENT_PERFORMANCE_ID: `set_current_performance_id`,
  SET_DOCUMENT: `set_document`,

  SET_ERROR_INFO: `set_error_info`,

  SET_GROUP_JOIN: `set_group_join`,
  SET_GROUP_PRIZE_MANUAL: `set_group_prize_manual`,
  SET_GROUP_RATING_SUM: `set_group_rating_sum`,

  SET_LOGOFF: `set_logoff`,
  SET_LOGON: `set_logon`,

  SET_MAIL_TEMPLATE: `set_mail_template`,
  SET_MESSAGE: `set_message`,
  SET_NOTE: `set_note`,

  SET_NOMINATION_RATING_METHOD: `set_nomination_rating_method`,

  SET_ORGANIZATION_CITY: `set_organization_city`,
  SET_ORGANIZATION_CITY_PRINT: `set_organization_city_print`,
  SET_ORGANIZATION_SMTP: `set_organization_smtp`,
  SET_ORGANIZATION_TITLE: `set_organization_title`,

  SET_PAGE_SETTINGS: `set_page_settings`,

  SET_PEOPLE_AUTH_EMAIL: `set_people_auth_email`,
  SET_PEOPLE_CARD: `set_people_card`,
  SET_PEOPLE_CONTEST: `set_people_contest`,
  SET_PEOPLE_DOB: `set_people_dob`,
  SET_PEOPLE_EMAIL: `set_people_email`,
  SET_PEOPLE_NAME: `set_people_name`,
  SET_PEOPLE_NEWS: `set_people_news`,
  SET_PEOPLE_PHONE_NUMBER: `set_people_phone_number`,

  SET_PERFORMANCE_AGE_CATEGORY: `set_performance_age_category`,
  SET_PERFORMANCE_ARCHIVE: `set_performance_archive`,
  SET_PERFORMANCE_CITY: `set_performance_city`,
  SET_PERFORMANCE_CONTACT: `set_performance_contact`,
  SET_PERFORMANCE_CONTEST: `set_performance_contest`,
  SET_PERFORMANCE_COPY: `set_performance_copy`,
  SET_PERFORMANCE_DELETE: `set_performance_delete`,
  SET_PERFORMANCE_DIRECTOR: `set_performance_director`,
  SET_PERFORMANCE_DOCUMENT_PRINTED: `set_performance_document_printed`,
  SET_PERFORMANCE_DURATION: `set_performance_duration`,
  SET_PERFORMANCE_FILE: `set_performance_file`,
  SET_PERFORMANCE_GROUP: `set_performance_group`,
  SET_PERFORMANCE_LINK: `set_performance_link`,
  SET_PERFORMANCE_MONEY: `set_performance_money`,
  SET_PERFORMANCE_NOMINATION: `set_performance_nomination`,
  SET_PERFORMANCE_ORGANIZATION: `set_performance_organization`,
  SET_PERFORMANCE_PHONOGRAM: `set_performance_phonogram`,
  SET_PERFORMANCE_PHONOGRAM_STATUS: `set_performance_phonogram_status`,
  SET_PERFORMANCE_PLAYER: `set_performance_player`,
  SET_PERFORMANCE_PLAYER_COUNT: `set_performance_player_count`,
  SET_PERFORMANCE_PLAYER_COUNT_NAME_SHOW: `set_performance_player_count_name_show`,
  SET_PERFORMANCE_PRIZE_MANUAL: `set_performance_prize_manual`,
  SET_PERFORMANCE_RATING: `set_performance_rating`,
  SET_PERFORMANCE_RATING_SUM: `set_performance_rating_sum`,
  SET_PERFORMANCE_REGISTER: `set_performance_register`,
  SET_PERFORMANCE_TEAM: `set_performance_team`,
  SET_PERFORMANCE_TITLE: `set_performance_title`,
  SET_PERFORMANCE_TURN: `set_performance_turn`,
  SET_PERFORMANCE_QUALIFICATION: `set_performance_qualification`,

  SET_PHONOGRAM_START_WITH_POINT: `set_phonogram_start_with_point`,
  SET_PHONOGRAM_TITLE: `set_phonogram_title`,

  SET_PLAYER_DIPLOM: `set_player_diplom`,

  SET_REFEREE_CRITERIA: `set_referee_criteria`,
  SET_REFEREE_PERFORMANCE_NOW: `set_referee_performance_now`,

  SET_SHORT_LINK: `set_short_link`,
  SET_STATE: `set_state`,

  SET_TEAM_CITY: `set_team_city`,
  SET_TEAM_CITY_PRINT: `set_team_city_print`,
  SET_TEAM_ORGANIZATION: `set_team_organization`,
  SET_TEAM_TITLE: `set_team_title`,
  SET_TERMINAL_MASTER: `set_terminal_master`,
  SET_TELEGRAM_MESSAGE: `set_telegram_message`,

  SET_USER_DATA: `set_user_data`,


  SHORT_LINK: `short_link`,
  SIZE: `size`,
  SMTP: `smtp`,
  SOUNDMAN: `soundman`,
  SOUNDMAN_ID: `soundman_id`,
  SOURCE: `source`,
  SOURCE_ID: `source_id`,
  SOURCE_TYPE: `source_type`,

  STACK: `stack`,
  START_DATE: `start_date`,
  START_WITH_POINT: `start_with_point`,
  STATE: `state`,
  STATUS: `status`,

  STOP: `stop`,

  SUB_NOMINATION: `sub_nomination`,
  SURNAME: `surname`,

  TABLES: `tables`,
  TABLE: `table`,
  TARGET: `target`,
  TEAM_ALIAS: `team_alias`,
  TEAM_CITY: `team_city`,
  TEAM_CITY_PRINT: `team_city_print`,
  TEAM_GOOGLE_CITY: `team_google_city`,
  TEAM_ID: `team_id`,
  TEAM_TITLE: `team_title`,

  TEMPLATE_ID: `template_id`,
  TEMPLATE_NAME: `template_name`,
  TERMINAL: `terminal`,
  TERMINAL_ID: `terminal_id`,
  TEXT: `text`,
  TIME: `time`,
  TIME_ZONE: `time_zone`,
  TITLE: `title`,
  TURN: `turn`,
  TYPE: `type`,

  TWO: `2`,

  UNDEFINED: `undefined`,
  USER_FIELDS: `user_fields`,
  USER_FIELD_ID: `user_field_id`,
  USER_MESSAGE: `user_message`,
  UP: `up`,

  WARMING: `warming`,
  WATER: `water`,
  WEIGHT: `weight`,

  ZERO: `0`,
};
