import {RegistrationField} from "./registration-filed";

export const NOMINATION_CONFIG = {
  name: RegistrationField.NOMINATION,
  setting: {
    title: `Номинация`,
    subtitleLevel1: `Подноминация`,
    subtitleLevel2: `Подноминация`,
    subtitleLevel3: `Подноминация`,
    subtitleLevel4: `Подноминация`,
    subtitleLevel5: `Подноминация`,
    isRequired: false,
    isSaveDataAfterRegistration: true,
    placeholder: ``,
    ifElse: ``,
  }
};
