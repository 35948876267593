export const RegistrationField = {
  AUTHORIZATION: `authorization`,
  CONTEST: `contest`,
  PERFORMANCE: `performance`,

  NOMINATION: `nomination`,
  AGE_CATEGORY: `age_category`,
  QUALIFICATION: `qualification`,

  ORGANIZATION: `organization`,
  TEAM: `team`,
  CITY: `city`,

  PLAYERS: `players`,
  DIRECTORS: `directors`,
  CONTACT: `contact`,

  FILES: `files`,

  FILE: `file`,
  LINK: `link`,
  PHONOGRAMS: `phonograms`,

  START_POINT: `start_point`,
  DURATION: `duration`,
  NOTE: `note`,

  PAYMENT: `payment`,
  REGISTRATION_POPUP: `registration_popup`,

  COMMON: `common`,
};
