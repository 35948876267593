import {ScriptError} from "../../../error/script";

/**
 * Функция проверки массива на пустоту
 * @param {any[]} array проверяемый массив строк, чисел или объектов
 * @returns {boolean} булево значение true/false.
 * Если передан НЕ массив, то вывод ошибки в консоль и возврат true,
 * что означает что массив пустой (или его нет)
 */

export const isEmptyArray = (array) => {
  try {
    if (!Array.isArray(array)) {
      throw new ScriptError({
        message: `в функцию isEmptyArray передан НЕ массив: ${array}`,
      });
    }

    return !array.length;

  } catch (error) {
    console.error(error);
    return true;
  }
};
