import {PLAYERS_TEXT_BLOCKS} from "./players-text-blocks";
import {RegistrationField} from "./registration-filed";

// export const PLAYERS_CONFIG = {
//   name: RegistrationField.PLAYERS,
//   setting: {
//     title: `Участники`,
//     isRequired: true,
//     isSurnameRequired: true,
//     isLastnameRequired: true,

//     isShowMiddlename: true,
//     isMiddlenameRequired: false,

//     isShowEmail: true,
//     isRequiredEmail: false,

//     isShowPhone: true,
//     isRequiredPhone: false,

//     isSaveDataAfterRegistration: true,
//     textBlocks: PLAYERS_TEXT_BLOCKS,
//     ifElse: ``,
//   }
// };
export const PLAYER_CARD_CONFIG = {
  name: `player_card`,
  title: `Карточка участника`,
  isSurnameRequired: true,
  isLastnameRequired: true,

  isShowMiddlename: true,
  isMiddlenameRequired: false,

  isShowEmail: true,
  isRequiredEmail: false,

  isShowPhone: true,
  isRequiredPhone: false,
  components: []
};

export const PLAYERS_CONFIG = {
  name: RegistrationField.PLAYERS,
  setting: {
    title: `Участники`,
    isRequired: true,

    isSaveDataAfterRegistration: true,
    textBlocks: PLAYERS_TEXT_BLOCKS,

    ifElse: ``,
    peopleCard: PLAYER_CARD_CONFIG,
  }
};
