import {isEmptyArray} from '../../utils/array/is-empty-array/is-empty-array';
import {isEmptyObject, parseColor, parseNameTypeBasic, getFormatFile} from '../../utils/common';
import {FileType, DEFAULT_FULL_NAME} from './../../const';
import {NTR} from './../../name-to-request';


export default class ParsingData {
  static documentPrinted(data) { // +
    const printed = data.document_printed;

    if (!printed || printed === `null` || printed === NTR.NO_ACCESS) {
      return [];
    }

    return printed.map((print) => {
      return {
        printId: String(print.print_id),
        isPrinted: String(print.date_print) !== NTR.ZERO,
        // isPrinted: print.print_id !== NTR.ZERO,
        date: print.date_print,
      };
    });
  }

  static peopleFinded(data) {
    const getPeoples = (peopleData, typeSearch = `other`) => {
      if (!peopleData) {
        return [];
      }

      const peoples = [];

      for (let peopleId in peopleData) {
        if (peopleId === `count`) {
          continue;
        }

        const people = peopleData[peopleId];

        const surname = people.surname || ``;
        const lastname = people.lastname || ``;
        const middlename = people.middlename || ``;
        const emailMask = people.email || ``;
        const phoneMask = people.phone || ``;
        const city = people.city || ``;
        const type = `человек`;

        peoples.push({peopleId, surname, lastname, middlename, emailMask, phoneMask, city, type, typeSearch});
      }

      return peoples;
    };

    const parsedPeople = data.people;

    if (isEmptyObject(parsedPeople.other) && isEmptyObject(parsedPeople.related)) {
      return [];
    }

    return [].concat(getPeoples(parsedPeople.related, `related`), getPeoples(parsedPeople.other, `other`));
  }


  static performanceFinded(data) {
    const parsedPerformance = data.performance;

    if (parsedPerformance[`0`] && parsedPerformance[`0`] === `не найдено`) {
      return [];
    }

    let peoples = [];

    for (let performanceId in parsedPerformance) {
      if (performanceId === `count`) {
        continue;
      }

      const performance = parsedPerformance[performanceId];
      const type = `выступление`;

      peoples.push({performanceId, title: performance, type});
    }

    return peoples;
  }

  static peopleCard(data) { // +
    const peopleCard = data.people_card;

    if (!peopleCard || peopleCard === NTR.NO_ACCESS) {
      return [];
    }

    const cardIds = Object.keys(peopleCard);

    return cardIds.map((cardId) => {
      return {
        cardId,
        cardNumber: peopleCard[cardId].card_number,
        isActive: peopleCard[cardId].card_state === NTR.ONE,
        isAttachmentContest: false,
        isAttachmentAll: false,
      };
    });
  }

  static peopleDirector(data) { // +
    const peopleDirector = data.people_director;

    if (!peopleDirector || peopleDirector === NTR.NO_ACCESS) {
      return [];
    }

    return peopleDirector.split(`,`)
      .map((position) => {
        return {
          directorId: ``,
          title: position.trim()
        };
      });
  }

  static peopleDob(data) { // +
    // приходит в формате 1987-10-16
    const peopleDob = data.people_dob;

    return !peopleDob || peopleDob === NTR.NO_ACCESS ? `` : peopleDob;
  }

  static peopleEmail(data) { // +
    const peopleEmail = data.people_email;

    if (!peopleEmail || peopleEmail === NTR.NO_ACCESS) {
      return [];
    }

    const mailIds = Object.keys(peopleEmail);

    return mailIds.map((mailId) => {
      return {
        mailId,
        mail: peopleEmail[mailId],
      };
    });
  }

  static peopleName(data) { // +
    const peopleName = data.people_name;

    return !peopleName || peopleName === NTR.NO_ACCESS ? DEFAULT_FULL_NAME : peopleName;
  }

  static peoplePass(data) { // +
    const peoplePass = data.people_pass;

    if (!peoplePass || peoplePass === NTR.NO_ACCESS) {
      return false;
    }

    return peoplePass === `yes`;
  }

  static peoplePhone(data) { // +
    const peoplePhone = data.people_phone_number;

    if (!peoplePhone || peoplePhone === NTR.NO_ACCESS) {
      return [];
    }

    const phoneIds = Object.keys(peoplePhone);

    return phoneIds.map((phoneId) => {
      return {
        phone: peoplePhone[phoneId],
      };
    });
  }

  static performanceAgeCategory(data) { // +
    const ageCategory = data.performance_age_category;

    if (!ageCategory || ageCategory === NTR.NO_ACCESS) {
      return {
        ageCategoryId: NTR.EMPTY,
        title: NTR.EMPTY,
        color: NTR.EMPTY,
      };
    }

    return {
      ageCategoryId: ageCategory.age_category_id ? ageCategory.age_category_id : NTR.EMPTY,
      title: !ageCategory.age_category_title || ageCategory.age_category_title.indexOf(`н/д`) >= 0 ? NTR.EMPTY : ageCategory.age_category_title,
      color: ageCategory.age_category_color ? parseColor(ageCategory.age_category_color) : NTR.EMPTY,
    };
  }

  static performanceArchive(data) { // +
    const arhive = data.performance_archive;

    return arhive && arhive === NTR.NO_ACCESS && arhive === NTR.ONE;
  }

  static performanceCid(data) { // +
    const performanceCid = data.performance_cid;

    return (!performanceCid || performanceCid === NTR.NO_ACCESS) ? NTR.EMPTY : performanceCid;
  }

  static performanceCity(data) { // +
    const city = data.performance_city;

    return (!city || city === NTR.NO_ACCESS || city.indexOf(`н/д`) >= 0) ? NTR.EMPTY : city;
  }

  static performanceCityId(data) { // +
    const cityId = data.performance_city_id;

    return (!cityId || cityId === NTR.NO_ACCESS || cityId === null) ? NTR.EMPTY : cityId;
  }

  static performanceContact(data) { // +
    const performanceContact = data.performance_contact;

    if (!performanceContact || isEmptyObject(performanceContact) || performanceContact === NTR.NO_ACCESS) {
      return null;
    }

    const peopleIds = Object.keys(performanceContact);
    const people = performanceContact[peopleIds[0]];

    return {
      peopleId: peopleIds[0],
      surname: people.surname,
      lastname: people.lastname,
      middlename: people.middlename,
      type: `контактное лицо`,
    };
  }

  static performanceContent(data) { // +
    const performanceContent = data.performance_content;

    if (!performanceContent || isEmptyArray(performanceContent) || performanceContent === NTR.NO_ACCESS) {
      return [];
    }

    const links = performanceContent
      .filter((content) => content.link)
      .map((content) => {
        return {
          linkId: content.link_id || ``,
          link: content.link || ``,
          preview: (content.preview && content.preview !== `--`) ? content.preview : ``,
          type: FileType.LINK,
        };
      });

    const videos = performanceContent
      .filter((content) => content.file)
      .filter((content) => {
        const format = getFormatFile(content.file);
        return format === `mp4`;
      })
      .map((content) => {
        return {
          fileId: content.file_id,
          source: content.file || ``,
          type: FileType.VIDEO,
        };
      });

    const images = performanceContent
      .filter((content) => content.file)
      .filter((content) => {
        const format = getFormatFile(content.file);
        return format === `jpg` || format === `jpeg` || format === `png`;
      })
      .map((content) => {
        return {
          fileId: content.file_id,
          source: content.file || ``,
          type: FileType.IMAGE,
        };
      });

    const unknown = performanceContent
      .filter((content) => content.file)
      .filter((content) => {
        const format = getFormatFile(content.file);
        return format !== `jpg` && format !== `jpeg` && format !== `png` && format !== `mp4`;
      })
      .map((content) => {
        return {
          fileId: content.file_id,
          source: content.file || ``,
          type: FileType.UNKNOWN,
        };
      });


    return [].concat(links, videos, images, unknown);
  }

  static performanceDirector(data) { // +
    const performanceDirector = data.performance_director;

    if (!performanceDirector || performanceDirector === NTR.NO_ACCESS) {
      return [];
    }

    const directorIds = Object.keys(performanceDirector);

    const directors = directorIds.map((directorId) => {
      const director = performanceDirector[directorId].split(`,`);
      const name = parseNameTypeBasic(director[0].trim().split(` `));

      return {
        directorId,
        surname: name.surname,
        lastname: name.lastname,
        middlename: name.middlename,
        title: director[1].replace(/\s+/g, ` `).trim(),
        peopleId: director[2].replace(/\s+/g, ` `).trim(),
        phones: [],
        emails: [],
        type: `руководитель`,
      };
    });

    const getPeople = ({peopleId, surname, lastname, middlename, phones, emails, type}) => {
      return {
        peopleId,
        surname,
        lastname,
        middlename,
        phones,
        emails,
        type,
      };
    };

    const getPosition = ({directorId, title}) => {
      return {
        directorId,
        title
      };
    };

    const peoples = [];

    directors.forEach((director) => {
      if (!peoples.some((people) => people.peopleId === director.peopleId)) {
        peoples.push(getPeople(director));
      }
    });

    peoples.forEach((people) => {
      people.positions = [];
      directors.forEach((director) => {
        if (people.peopleId === director.peopleId) {
          people.positions.push(getPosition(director));
        }
      });
    });

    return peoples;
  }

  static performanceEntryAfter(data) { // +
    const performanceEntry = data.performance_entry;

    return (!performanceEntry || performanceEntry === NTR.NO_ACCESS || !performanceEntry.after || performanceEntry.after === NTR.NO_ACCESS) ? NTR.EMPTY : performanceEntry.after;
  }

  static performanceEntryBefore(data) { // +
    const performanceEntry = data.performance_entry;

    return (!performanceEntry || performanceEntry === NTR.NO_ACCESS || !performanceEntry.before || performanceEntry.before === NTR.NO_ACCESS) ? NTR.EMPTY : performanceEntry.before;
  }

  static performanceGroup(data) { // +
    const performanceGroup = data.performance_group;

    return (!performanceGroup || performanceGroup === NTR.NO_ACCESS) ? NTR.EMPTY : performanceGroup;
  }

  static performanceMoney(data) { // +
    const performanceMoney = data.performance_money;

    if (!performanceMoney || performanceMoney === NTR.NO_ACCESS) {
      return {
        status: `0`,
        toPay: 0,
        paid: 0,
      };
    }

    return {
      status: (!performanceMoney.money_status || performanceMoney.money_status === null) ? `0` : performanceMoney.money_status,
      toPay: (!performanceMoney.amount_to_pay || performanceMoney.amount_to_pay === 0 || performanceMoney.amount_to_pay === null) ? 0 : +performanceMoney.amount_to_pay,
      paid: (!performanceMoney.amount_paid || performanceMoney.amount_paid === 0 || performanceMoney.amount_paid === null) ? 0 : +performanceMoney.amount_paid,
    };
  }

  static performanceNomination(data) { // +
    const nomination = data.performance_nomination;

    if (!nomination || nomination === NTR.NO_ACCESS) {
      return {
        nominationId: NTR.EMPTY,
        title: NTR.EMPTY,
        color: NTR.EMPTY,
      };
    }

    return {
      nominationId: nomination.nomination_id ? nomination.nomination_id : ``,
      title: !nomination.nomination_title || nomination.nomination_title.indexOf(`н/д`) >= 0 ? NTR.EMPTY : nomination.nomination_title,
      color: nomination.nomination_color ? parseColor(nomination.nomination_color) : ``,
    };
  }

  static performanceOrganization(data) { // +
    return {
      organizationId: ParsingData.performanceOrganizationId(data),
      title: ParsingData.performanceOrganizationTitle(data),
      city: ParsingData.performanceOrganizationCity(data),
      cityId: ``,
      isCityPrint: ParsingData.organizationCityPrint(data),
      isRepresent: data.performance_organization_id === `0`,
    };
  }

  static organizationCityPrint(data) {
    const organizationCityPrint = data.organization_city_print;

    return !!organizationCityPrint && organizationCityPrint !== NTR.NO_ACCESS && organizationCityPrint === NTR.ONE;
  }

  static performanceOrganizationId(data) { // +
    const performanceOrganizationId = data.performance_organization_id;

    return (!performanceOrganizationId || performanceOrganizationId === NTR.NO_ACCESS) ? NTR.EMPTY : performanceOrganizationId;
  }

  static performanceOrganizationTitle(data) { // +
    const performanceOrganizationTitle = data.performance_organization_title;

    return (!performanceOrganizationTitle || performanceOrganizationTitle === NTR.NO_ACCESS || performanceOrganizationTitle.indexOf(`н/д`) >= 0 || performanceOrganizationTitle === null) ? NTR.EMPTY : performanceOrganizationTitle;
  }

  static performanceOrganizationCity(data) { // +
    const performanceOrganizationCity = data.organization_city;

    return (!performanceOrganizationCity || performanceOrganizationCity === NTR.NO_ACCESS || performanceOrganizationCity.indexOf(`н/д`) >= 0 || performanceOrganizationCity === null) ? NTR.EMPTY : performanceOrganizationCity;
  }

  static performanceQualification(data) { // +
    const performanceQualification = data.performance_qualification;

    if (!performanceQualification || performanceQualification === NTR.NO_ACCESS || !performanceQualification.qualification_id || !performanceQualification.qualification_id === null) {
      // if (!performanceQualification || performanceQualification === NTR.NO_ACCESS) {
      return {
        qualificationId: NTR.EMPTY,
        title: NTR.EMPTY,
        color: NTR.EMPTY,
      };
    }

    return {
      qualificationId: performanceQualification.qualification_id,
      title: performanceQualification.qualification_title.indexOf(`н/д`) >= 0 ? NTR.EMPTY : performanceQualification.qualification_title,
      color: parseColor(performanceQualification.qualification_color),
    };
  }

  static performancePhonograms(data) { // +
    const phonogramTitle = data.phonogram_title;

    if (!phonogramTitle || phonogramTitle === NTR.NO_ACCESS) {
      return [];
    }

    const phonogramIds = Object.keys(phonogramTitle);

    return phonogramIds.map((phonogramId) => {
      return {
        id: phonogramId,
        title: ParsingData.phonogramTitle(data, phonogramId),
        format: ParsingData.phonogramFormat(data, phonogramId),
        size: ParsingData.phonogramSize(data, phonogramId),
        date: ParsingData.phonogramDate(data, phonogramId),
        startPoint: ParsingData.phonogramStartWithPoint(data, phonogramId),
        duration: ParsingData.phonogramDuration(data, phonogramId),
        status: ParsingData.performancePhonogramStatus(data, phonogramId),
      };
    });
  }

  static performancePhonogramStatus(data, phonogramId) { // +
    const performancePhonogramStatus = data.performance_phonogram_status;

    if (!performancePhonogramStatus || performancePhonogramStatus === NTR.NO_ACCESS || !performancePhonogramStatus[phonogramId]) {
      return ``;
    }

    switch (performancePhonogramStatus[phonogramId]) {
      case null:
        return {name: `Не загружена`, id: `none`, info: `base`};
      case ``:
        return {name: `Не загружена`, id: `none`, info: `base`};
      case `0`:
        return {name: `Не проверена`, id: `0`, info: `check`};
      case `1`:
        return {name: `Все не проверены`, id: `1`, info: `check`};
      case `2`:
        return {name: `Не все проверены`, id: `2`, info: `check`};
      case `5`:
        return {name: `Принята`, id: `5`, info: `ok`};
      case `6`:
        return {name: `Все одобрены`, id: `6`, info: `ok`};
      case `10`:
        return {name: `Отклонена`, id: `10`, info: `error`};
      case `11`:
        return {name: `Отклонена: низкое качество звука`, id: `11`, info: `error`};
      case `20`:
        return {name: `Все отклонены`, id: `20`, info: `error`};
      case `21`:
        return {name: `Не все приняты`, id: `21`, info: `error`};
      default:
        return {name: `Не загружена`, id: `none`, info: `base`};
    }
  }

  static phonogramGeneralStatus(data) { // +
    const status = data.performance_phonogram_status;

    if (!status) {
      return {};
    }

    if (isEmptyObject(status)) {
      return {
        [NTR.EMPTY]: null
      };
    }

    const keys = Object.keys(status);

    if (keys.length === 1) {
      return {
        [keys[0]]: status[keys[0]]
      };
    }

    const index = keys.findIndex((it) => it === ``);
    if (index === -1) {
      return {
        [keys[0]]: status[keys[0]]
      };
    }

    return {
      [keys[index]]: status[keys[index]]
    };
  }

  static performanceNote(data) { // +
    const performanceNote = data.performance_note;

    if (!performanceNote || performanceNote === NTR.NO_ACCESS) {
      return [];
    }

    const noteIds = Object.keys(performanceNote);

    const notes = noteIds.map((noteId) => {
      const note = performanceNote[noteId];

      return {
        noteId,
        text: note.note || ``,
        date: note.time,
        authorId: note.author_id,
        authorType: note.author_type,
        isAccess: true,
      };
    });

    return notes.sort((a, b) => b.date - a.date);
  }

  static performancePlayer(data) { // +
    const performancePlayer = data.performance_player;

    if (!performancePlayer || performancePlayer === NTR.NO_ACCESS) {
      return [];
    }

    const playerIds = Object.keys(performancePlayer);

    return playerIds.map((playerId) => {
      const player = performancePlayer[playerId];
      return {
        playerId,
        surname: player.surname || ``,
        lastname: player.lastname || ``,
        middlename: player.middlename || ``,
        title: player.player_title || ``,
        type: `участник`,
        phones: [],
        emails: [],
      };
    });
  }

  static performancePlayerCount(data) { // +
    const performancePlayerCount = data.performance_player_count;

    return (!performancePlayerCount || performancePlayerCount === NTR.NO_ACCESS) ? NTR.NULL : +performancePlayerCount;
  }

  static performancePrize(data) { // +
    const prize = data.performance_prize;

    return (prize && prize !== NTR.NO_ACCESS) ? prize : NTR.EMPTY;
  }

  static performancePrizeManual(data) { // +
    const prize = data.performance_prize_manual;

    return (prize && prize !== NTR.NO_ACCESS) ? prize : NTR.EMPTY;
  }

  // static performanceRating(data) { // +
  //   const performanceRating = data.performance_rating;

  //   if (!performanceRating || performanceRating === NTR.NOT_REQUIRED || performanceRating === NTR.NO_ACCESS) {
  //     return [];
  //   }

  //   const criteriaIds = Object.keys(performanceRating);

  //   return criteriaIds.map((criteriaId) => {
  //     const ratingData = performanceRating[criteriaId];
  //     return {
  //       criteriaId,
  //       rating: ratingData === `-` ? `` : ratingData,
  //     };
  //   });
  // }

  static performanceRating(data) { // врменный парсинг, чтоб пока дальше не переделывать
    const performanceRating = data.performance_rating;

    // 189080498: {22: "9.0"}

    if (!performanceRating || performanceRating === NTR.NO_ACCESS) {
      return [];
    }

    const result = [];

    const refereeIds = Object.keys(performanceRating);

    refereeIds.forEach((refereeId) => {
      const rating = performanceRating[refereeId];

      if (rating && rating !== NTR.NOT_REQUIRED) {
        const criteriaIds = Object.keys(rating);
        criteriaIds.map((criteriaId) => {
          const ratingData = rating[criteriaId];
          result.push(
              {
                criteriaId,
                rating: ratingData === `-` ? `` : ratingData,
              }
          );
        });
      }

    });

    return result;
  }

  static performanceAllRating(data) { // +
    const rating = data.performance_rating;

    return (rating && rating !== NTR.NOT_REQUIRED && rating !== NTR.NO_ACCESS) ? rating : {};
  }

  static performanceRatingSum(data) { // +
    const rating = data.performance_rating_sum;

    return (rating && rating !== NTR.NO_ACCESS) ? rating : NTR.EMPTY;
  }

  static performanceTeam(data) { // +
    return {
      teamId: ParsingData.performanceTeamId(data),
      title: ParsingData.performanceTeamTitle(data),
      city: ParsingData.performanceTeamCity(data),
      cityId: ``,
      isCityPrint: ParsingData.teamCityPrint(data),
      isRepresent: data.performance_team_id === `0`,
    };
  }

  static teamCityPrint(data) {
    const teamCityPrint = data.team_city_print;

    return !!teamCityPrint && teamCityPrint !== NTR.NO_ACCESS && teamCityPrint === NTR.ONE;
  }

  static performanceTeamId(data) { // +
    const performanceTeamId = data.performance_team_id;

    return (!performanceTeamId || performanceTeamId === NTR.NO_ACCESS) ? NTR.EMPTY : performanceTeamId;
  }

  static performanceTeamTitle(data) { // +
    const performanceTeamTitle = data.performance_team_title;

    return (!performanceTeamTitle || performanceTeamTitle === NTR.NO_ACCESS || performanceTeamTitle.indexOf(`н/д`) >= 0 || performanceTeamTitle === null) ? NTR.EMPTY : performanceTeamTitle;
  }

  static performanceTeamCity(data) { // +
    const performanceTeamCity = data.performance_team_city;

    return (!performanceTeamCity || performanceTeamCity === NTR.NO_ACCESS || performanceTeamCity.indexOf(`н/д`) >= 0 || performanceTeamCity === null) ? NTR.EMPTY : performanceTeamCity;
  }

  static performanceTimeFull(data) { // +
    const performanceTimeFull = data.performance_time_full;

    if (!performanceTimeFull || performanceTimeFull === NTR.NO_ACCESS || performanceTimeFull === `0`) {
      return NTR.NULL;
    }

    return performanceTimeFull;
  }

  static performanceTimeStart(data) { // +
    const timeStart = data.performance_time_start;

    return (!timeStart || timeStart === NTR.NO_ACCESS) ? NTR.EMPTY : timeStart;
  }

  static performanceTitle(data) { // +
    const performanceTitle = data.performance_title;

    if (!performanceTitle || performanceTitle === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return performanceTitle;
  }

  static performanceTurnId(data) { // +
    const performanceTurnId = data.performance_turn_id;

    return (!performanceTurnId || performanceTurnId === NTR.NO_ACCESS) ? NTR.EMPTY : performanceTurnId;
  }

  static performanceUserData(data) {
    const userData = data.performance_user_data;

    return (!userData || userData === NTR.NO_ACCESS || isEmptyArray(userData)) ? {} : userData;
  }

  static phonogramTitle(data, phonogramId) { // +
    const phonogramTitle = data.phonogram_title;

    return (!phonogramTitle || phonogramTitle === NTR.NO_ACCESS || !phonogramTitle[phonogramId]) ? `Фонограмма` : phonogramTitle[phonogramId];
  }

  static phonogramFormat(data, phonogramId) { // +
    const phonogramTitle = data.phonogram_title;

    return (!phonogramTitle || phonogramTitle === NTR.NO_ACCESS || !phonogramTitle[phonogramId]) ? `` : getFormatFile(phonogramTitle[phonogramId]);
  }

  static phonogramSize(data, phonogramId) { // +
    const phonogramSize = data.phonogram_size;

    return (!phonogramSize || phonogramSize === NTR.NO_ACCESS || !phonogramSize[phonogramId]) ? `` : +phonogramSize[phonogramId];
  }

  static phonogramDate(data, phonogramId) { // +
    const phonogramDate = data.phonogram_date;

    return (!phonogramDate || phonogramDate === NTR.NO_ACCESS || !phonogramDate[phonogramId]) ? `` : phonogramDate[phonogramId]; // timeConverter(json.phonogram_date[id], "day:month:year")
  }

  static phonogramStartWithPoint(data, phonogramId) { // +
    const phonogramStartWithPoint = data.phonogram_start_with_point;

    if (!phonogramStartWithPoint || phonogramStartWithPoint === NTR.NO_ACCESS || !phonogramStartWithPoint[phonogramId]) {
      return null;
    }

    switch (phonogramStartWithPoint[phonogramId]) {
      case null:
        return {
          name: `Не указано`,
          id: `null`
        };
      case `null`:
        return {
          name: `Не указано`,
          id: `null`
        };
      case `0`:
        return {
          name: `из кулис`,
          id: `0`
        };
      case `1`:
        return {
          name: `с точки`,
          id: `1`
        };
      default:
        return {
          name: `Не указано`,
          id: `null`
        };
    }
  }

  static phonogramDuration(data, phonogramId) { // +
    const phonogramDuration = data.performance_duration;

    return (!phonogramDuration || phonogramDuration === NTR.NO_ACCESS || !phonogramDuration[phonogramId]) ? NTR.NULL : +phonogramDuration[phonogramId]; // timeConverter(json.phonogram_date[id], "day:month:year")
  }

  static unblock(data) { // +
    const unblock = data.unblock;

    return !unblock || (unblock && unblock === NTR.NULL);
  }
}
