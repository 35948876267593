import API from "../../api";
import LoadAPI from "../../data/load/api";
import SetDataError from "../../data/set/error";
import Popup from "../../static/popup/popup";
import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import {getFormatFile, sliceBlob, stringRandom} from "../../utils/common";
import LoadController from "./load";

const BYTES_PER_CHUNK = 1024 * 1024; // 1MB chunk sizes.

export default class FileLoadController extends LoadController {
  constructor({api = new API(), container, maxSize = Infinity, permittedFormats = [], onLoadedFile = null}) {
    super();

    this._loadAPI = new LoadAPI({api});
    this._container = container;
    this._maxSize = maxSize;
    this._permittedFormats = permittedFormats;
    this._onLoadedFile = onLoadedFile;


    this._file = null;
    this._fileId = ``;

    this._startChunk = 0;
    this._endChunk = BYTES_PER_CHUNK;

    this._onErrorLoadHandler = this._onErrorLoadHandler.bind(this);
  }

  load(files) {
    const validates = this._getValidates(files);

    if (validates.some((item) => !item.isValid)) {
      this._showError(validates);
      return;
    }

    this._loadFiles(files);
  }

  _getValidates(files) {
    const file = files[0];
    const validates = [];

    validates.push(this._getMaxSizeValidate(file.size));
    validates.push(this._getFormatValidate(getFormatFile(file.name)));

    return validates;
  }

  _showError(validates) {
    const texts = [];
    texts.push(`<b>Файл не загружен.</b><br>`);

    validates
      .filter((item) => !item.isValid)
      .forEach((item) => {
        const text = this._getErrorText(item.type);
        texts.push(text);
      });

    Popup.showWarning({text: texts.join(`<br>`)});
  }

  _getErrorText(type) {
    switch (type) {
      case `maxSize`:
        return `Загрузите файл не больше <b>${this._maxSize}</b> Мбайт.`;
      case `format`:
        return `Загрузите файл в формате <b>${this._permittedFormats.join(`, `)}</b>.`;
      default:
        return ``;
    }
  }

  _getMaxSizeValidate(size) {
    return {
      type: `maxSize`,
      isValid: size <= this._maxSize * 1048576,
    };
  }

  _getFormatValidate(format) {
    // const format = getFormatFile(file);

    return {
      type: `format`,
      isValid: isEmptyArray(this._permittedFormats) || this._permittedFormats.includes(format),
    };
  }

  _loadFiles(files) {
    // можно применить утилиту promise-chain
    this._files = this._parseFiles(files);
    let chain = Promise.resolve();

    let results = [];

    this._renderProgress();

    this._files.forEach((file) => {
      chain = chain
        .then(() => this._loadFile(file))
        .then((result) => {
          results.push(result);
        });
    });

    chain.then(() => {
      this._onLoadDoneHandler(results);
    });
  }

  _parseFiles(files) {
    return Array.from(files).map((file, i) => {
      file.id = `file_${i}`;
      return file;
    });
  }

  _loadFile(file) {
    return new Promise((resolve, reject) => {
      this._file = file;
      this._fileId = stringRandom(10);
      this._orderId = file.id;

      this._loadPost(resolve, reject);
    })
    .catch(this._onErrorLoadHandler);
  }

  // загружаем часть файла, после загрузки проверяем все ли загружено
  _loadPost(resolve, reject) {
    const chunkBlob = sliceBlob(this._file, this._startChunk, this._endChunk);

    this._loadAPI.loadFileChunk(chunkBlob, this._fileId, this._startChunk, this._getAbortSignal())
    // this._api.loadFileChunkPost(chunkBlob, this._fileId, this._startChunk, this._getAbortSignal())
    .then(() => {
      this._onProgressLoadHandler(this._endChunk, this._file.size);

      this._startChunk = this._endChunk;
      this._endChunk = this._startChunk + BYTES_PER_CHUNK;

      this._checkLoaded(resolve, reject);
    })
    .catch((error) => {
      reject(error);
    });
  }

  _checkLoaded(resolve, reject) {
    if (this._startChunk < this._file.size) {
      this._loadPost(resolve, reject);
      return;
    }

    this._loadDone(resolve, reject);
  }

  _loadDone(resolve) {
    this._onProgressLoadHandler(this._file.size, this._file.size);
    this._startChunk = 0;
    this._endChunk = BYTES_PER_CHUNK;
    this._resolve = null;
    this._reject = null;

    this._loadAPI.loadFileDone(this._file.name, this._fileId)
    .then((fileId) => {
      resolve({fileId, name: this._file.name});
    });
  }

  _onLoadDoneHandler(files) {
    this._removeProgress();
    this._onLoadedFile(files);
  }

  _onErrorLoadHandler(error) {
    this._removeProgress();
    SetDataError.info(this._api, error.stack);
  }
}
