import AbstractSmartComponent from '../abstract-smart-component.js';

const createButtonTemplate = (className, id, title, prompt, isDisabled) => {
  return (
    `<button class="${className}" ${id ? `id="${id}"` : ``} type="button" name="button" ${prompt !== `` ? `title="${prompt}"` : ``} data-disabled="${isDisabled}">
      ${title}
    </button>`
  );
};

export default class ButtonComponent extends AbstractSmartComponent {
  constructor({className = ``, id = ``, name = ``, title = ``, prompt = ``, isDisabled = false}) {
    super();

    this._className = className;
    this._id = id;
    this._name = name;
    this._title = title;
    this._prompt = prompt;
    this._isDisabled = isDisabled;

    this._onClickHandler = null;
    this._onDoubleClickHandler = null;
    this._onClickWithLoaderHandler = null;
  }

  getTemplate() {
    return createButtonTemplate(this._className, this._id, this._title, this._prompt, this._isDisabled);
  }

  recoveryListeners() {
    this.setClickHandler(this._onClickHandler);
    this.setDoubleClickHandler(this._onDoubleClickHandler);
    this.setClickWithLoaderHandler(this._onClickWithLoaderHandler);
  }

  get isDisabled() {
    return this._isDisabled;
  }

  get name() {
    return this._name;
  }

  set isDisabled(data) {
    this._isDisabled = data;
    this.getElement().dataset.disabled = data;
  }

  set title(data) {
    this._title = data;
    this.getElement().textContent = data;
    // super.rerender();
  }

  setClickHandler(handler) {
    if (!handler) {
      return;
    }

    this.getElement().addEventListener(`click`, (evt) => {
      handler(this.name, evt);
    });

    this._onClickHandler = handler;
  }

  setDoubleClickHandler(handler) {
    if (!handler) {
      return;
    }

    this.getElement().addEventListener(`dblclick`, () => handler(this.name));

    this._onDoubleClickHandler = handler;
  }

  setClickWithLoaderHandler(handler) {
    if (!handler) {
      return;
    }

    this.getElement().addEventListener(`click`, () => {
      if (this.isDisabled) {
        return;
      }
      this.getElement().classList.add(`loaderButton`);
      this._isDisabled = true;

      handler({
        name: this.name,
        cbButton: () => {
          this.getElement().classList.remove(`loaderButton`);
          this._isDisabled = false;
        }
      });
    });

    this._onClickWithLoaderHandler = handler;
  }
}
