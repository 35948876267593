export const PLAYERS_TEXT_BLOCKS = [
  {
    note: `Текст перед полем количества участников`,
    text: `Укажите число участников`,
  },
  {
    note: `Текст перед блоками с данными по каждому участнику`,
    text: `Укажите данные каждого участника`,
  },
  {
    note: `Текст на блоке для ввода данных по каждому участнику`,
    text: `Укажите данные участника`,
  },
  {
    note: `Заголовок всплывающего окна при добавлении участника`,
    text: `Добавление участника`,
  },
  {
    note: `Заголовок всплывающего окна при замене участника`,
    text: `Замена участника`,
  },
  {
    note: `Заголовок всплывающего окна при редактировании данных участника`,
    text: `Редактирование данных участника`,
  },
  {
    note: `Заголовок всплывающего окна при удалении участника`,
    text: `Удаление участника`,
  },
  {
    note: `Текст всплывающего окна при удалении участника (после фразы идет ФИО участника и знак вопроса)`,
    text: `Вы уверены, что хотите удалить участника`,
  },
  {
    note: `Текст ошибки, когда не указано количество участников`,
    text: `Укажите число участников`,
  },
  {
    note: `Текст ошибки, когда не введены данные по всем участникам`,
    text: `Введите данные по всем участникам, или уменьшите их количество`,
  },
];
