import {NTR} from "../../name-to-request";
import Popup from "../../static/popup/popup";
import RequestController from "../../static/request/request";
import TransactionModel from "../transaction/model";
import PeopleModel from "./model";
import PeopleParsing from "./parsing";
import PeopleRequest from "./request";

export default class PeopleData {
  constructor({api, isShowError = true}) {
    this._api = api;
    this._isShowError = isShowError;
  }

  getOneData({peopleId, parameters}) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.getOne,
        parameter: {
          peopleId,
          parameters,
        },
        checkRequiredParameter: {peopleId},
        requiredResponseParameters: [],
        parseResponse: (data) => PeopleModel.parsePeople(peopleId, data),
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по человеку.`),
      }).send();
    });
  }

  addPeople(people) {
    // "People not found"}
    if (people.peopleId && people.peopleId !== ``) {
      return Promise.resolve(people.peopleId);
    }

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.name,
        parameter: {peopleId: NTR.ZERO, surname: people.surname, lastname: people.lastname, middlename: people.middlename},
        checkRequiredParameter: {},
        requiredResponseParameters: [NTR.PEOPLE_ID],
        parseResponse: PeopleParsing.peopleIdData,
        isShowError: this._isShowError,
        onResolveHandler: (peopleId) => resolve(peopleId),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось создать человека.`),
      }).send();
    });
  }

  changeName(people) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.name,
        parameter: {peopleId: people.peopleId, surname: people.surname, lastname: people.lastname, middlename: people.middlename},
        checkRequiredParameter: {peopleId: people.peopleId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(people.peopleId),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось создать человека.`),
      }).send();
    });
  }

  addEmail(peopleId, mail) {
    const getTextError = ({message}) => {
      if (/Duplicate entry .*? for key 'email_people'/.test(message)) {
        return `Такая электронная почта уже установлена для этого человека.`;
      }

      return `Не удалось добавить электронную почту.`;
    };

    return new Promise((resolve, reject) => {
      if (!mail || mail.indexOf(`*`) > 0) {
        resolve();
        return;
      }

      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.addEmail,
        parameter: {peopleId, newMail: mail},
        checkRequiredParameter: {peopleId, newMail: mail},
        requiredResponseParameters: [NTR.MAIL_ID],
        parseResponse: PeopleParsing.mailIdData,
        isShowError: this._isShowError,
        onResolveHandler: (mailId) => resolve(mailId),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  changeEmail(peopleId, oldMail, newMail) {
    const getTextError = ({message}) => {
      if (/Duplicate entry .*? for key 'email_people'/.test(message)) {
        return `Такая электронная почта уже установлена для этого человека.`;
      }

      return `Не удалось изменить электронную почту.`;
    };

    return new Promise((resolve, reject) => {
      if (!newMail.indexOf(`*`) > 0) {
        resolve();
        return;
      }

      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.changeEmail,
        parameter: {peopleId, oldMail, newMail},
        checkRequiredParameter: {peopleId, oldMail, newMail},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  deleteEmail(peopleId, oldMail) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.deleteEmail,
        parameter: {peopleId, oldMail},
        checkRequiredParameter: {peopleId, oldMail},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить электронную почту.`),
      }).send();
    });
  }

  addPhone(peopleId, newPhone) {
    return new Promise((resolve, reject) => {
      if (!newPhone || newPhone.indexOf(`*`) > 0) {
        resolve();
        return;
      }

      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.addPhone,
        parameter: {peopleId, newPhone},
        checkRequiredParameter: {peopleId, newPhone},
        requiredResponseParameters: [],
        // requiredResponseParameters: [NTR.MAIL_ID],
        // parseResponse: PeopleParsing.mailIdData,
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось добавить телефон.`),
      }).send();
    });
  }

  changePhone(peopleId, oldPhone, newPhone) {
    return new Promise((resolve, reject) => {
      if (!newPhone.indexOf(`*`) > 0) {
        resolve();
        return;
      }

      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.changePhone,
        parameter: {peopleId, oldPhone, newPhone},
        checkRequiredParameter: {peopleId, oldPhone, newPhone},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось изменить телефон.`),
      }).send();
    });
  }

  deletePhone(peopleId, oldPhone) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.deletePhone,
        parameter: {peopleId, oldPhone},
        checkRequiredParameter: {peopleId, oldPhone},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить телефон.`),
      }).send();
    });
  }

  // setPhones(people) {
  //   const phones = people.phones;

  //   return Promise.all(
  //       phones.filter((phone) => phone.state !== `ok`)
  //       .map((phone) => {
  //         if (phone.state === `add`) {
  //           return this.addPhone(people.peopleId, phone);
  //         }
  //         if (phone.state === `change`) {
  //           return this.changePhone(people.peopleId, phone);
  //         }
  //         if (phone.state === `delete`) {
  //           return this.deletePhone(people.peopleId, phone);
  //         }
  //         return null;
  //       })
  //   );
  // }

  getAllCard() {
    return new Promise((resolve) => {
      const peopleId = ``;
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.getAllCard,
        parameter: {},
        checkRequiredParameter: {},
        requiredResponseParameters: [],
        parseResponse: (data) => PeopleModel.parsePeople(peopleId, data),
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по картам.`),
      }).send();
    });
  }

  getContestCard(paymentRole) {
    return new Promise((resolve) => {
      const peopleId = ``;
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.getContestCard,
        parameter: {paymentRole},
        checkRequiredParameter: {paymentRole},
        requiredResponseParameters: [],
        parseResponse: (data) => PeopleModel.parsePeople(peopleId, data),
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по картам для мероприятия.`),
      }).send();
    });
  }

  setStatusCard(cardId, isActive) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.setStatusCard,
        parameter: {cardId, isActive},
        checkRequiredParameter: {cardId, isActive},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось активировать/деактивировать карту.`),
      }).send();
    });
  }

  deleteCard(cardId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.deleteCard,
        parameter: {cardId},
        checkRequiredParameter: {cardId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить карту.`),
      }).send();
    });
  }

  delegationCard(cardId, peopleId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.delegationCard,
        parameter: {cardId, peopleId},
        checkRequiredParameter: {cardId, peopleId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось делегировать карту.`),
      }).send();
    });
  }

  getTransaction({filter}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.getTransaction,
        parameter: {filter},
        checkRequiredParameter: {},
        requiredResponseParameters: [],
        parseResponse: (data) => TransactionModel.parseTransactions(data),
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по оплатам.`),
      }).send();
    });
  }

  setPeopleContest(contestId, role) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.setPeopleContest,
        parameter: {contestId, role},
        checkRequiredParameter: {contestId, role},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось изменить мероприятие.`),
      }).send();
    });
  }

  setAuthEmail({peopleId, email, notAutnEmailHandler}) {
    const onErrorHandler = (error) => {
      if (error.message === `e-mail exists`) {
        notAutnEmailHandler();
        return;
      }

      Popup.showError({text: `Не удалось добавить адрес электронной почты.`});
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PeopleRequest.setAuthEmail,
        parameter: {peopleId, email},
        checkRequiredParameter: {peopleId, email},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => onErrorHandler(error),
      }).send();
    });
  }
}
