import {DIRECTORS_TEXT_BLOCKS} from "../directors-text-blocks";
import {RegistrationField} from "./registration-filed";

export const DIRECTORS_FORM_FIELD = {
  name: RegistrationField.DIRECTORS,
  setting: {
    title: `Руководители`,
    isRequired: true,
    positions: [],
    isShowCheckboxIsNoDirector: true,
    isSurnameRequired: true,
    isLastnameRequired: true,
    isMiddlenameRequired: true,
    isPositionRequired: true,
    isRequiredEmail: false,
    isRequiredPhone: false,
    isShowMiddlename: true,
    isShowPosition: true,
    isShowEmail: true,
    isShowPhone: true,
    isReadOnlyPositions: false,
    textBlocks: DIRECTORS_TEXT_BLOCKS,
    ifElse: ``,
  }
};
