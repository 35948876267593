export const DIRECTORS_TEXT_BLOCKS = [
  {
    note: `Кнопка добавления руководителя`,
    text: `Добавить руководителя`,
  },
  {
    note: `Текст, когда еще не добавлены руководители, между кнопкой добавления и галочкой`,
    text: `Или если у Вас не руководителя, то поставьте галочку`,
  },
  {
    note: `Текст для галочки, когда нет руководителя`,
    text: `У меня нет руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при добавлении руководителя`,
    text: `Добавление руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при замене руководителя`,
    text: `Замена руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при редактировании данных руководителя`,
    text: `Редактирование данных руководителя`,
  },
  {
    note: `Заголовок всплывающего окна при удалении руководителя`,
    text: `Удаление руководителя`,
  },
  {
    note: `Текст всплывающего окна при удалении руководителя (после фразы идет ФИО руководителя и знак вопроса)`,
    text: `Вы уверены, что хотите удалить руководителя`,
  },
  {
    note: `Текст ошибки, если не добавлен руководителя или не поставлена галочка`,
    text: `Добавьте руководителя, или поставьте галочку «У меня нет руководителя»`,
  },
];
