import LazyRouterController from '../../controllers/lazy-router';
import NotificationController from '../../controllers/notification';

export const PopupSize = {
  SMALL: `small`,
  MEDIUM: `medium`,
  LARGE: `large`,
  FULL: `full`,
};

export const PopupStyle = {
  OK: `ok`,
  // SUCCESS: `success`,
  WARNING: `warning`,
  ERROR: `error`,
};

export const ContentType = {
  TEXT: `text`,
  FRAGMENT: `fragment`,
  INPUT: `input`,
  INPUT_NEW: `input_new`,
  MULTI_INPUT: `multiInput`,
  SEARCH: `search`,
  BUTTONS: `buttons`,
  CUSTOM: `custom`,
  SEPARATOR: `separator`,
};


export default class Popup {
  static showCustom(property) {
    return Popup.getCustom(property)
    .then((popupController) => {
      popupController.render();
      return popupController;
    });
  }

  static getCustom(property) {
    return LazyRouterController.modules.popup()
    .then((module) => {
      return new module.PopupController(property);
    });
  }

  static showNotification({text = ``, isFade, color}) {
    const notification = new NotificationController({text, isFade, color});
    notification.render();

    return notification;
  }

  static showResolveChangeNotification() {
    const notification = new NotificationController({text: `Изменения внесены успешно!`});
    notification.render();
  }

  static showInfo({title = ``, text = ``, onClickHandler = () => {}}) {
    Popup.showCustom({
      size: PopupSize.SMALL,
      title,
      contents: [
        {
          type: ContentType.TEXT,
          content: text,
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `OK`,
              onClickHandler,
              isClose: true
            },
          ],
        }
      ]
    });
  }

  static showWarning({title = `Внимание!`, text = ``}) {
    Popup.showCustom({
      size: PopupSize.SMALL,
      style: PopupStyle.WARNING,
      title,
      contents: [
        {
          type: ContentType.TEXT,
          content: text,
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `OK`,
              onClickHandler: () => {},
              isClose: true
            },
          ],
        }
      ]
    });
  }

  static showError({title = `Произошла ошибка!`, text = `Не удалось внести изменения, попробуйте позже.`, isShowSupport = true}) {
    const popupErrorElements = document.querySelectorAll(`.popup[data-style="error"]`);

    popupErrorElements.forEach((elem) => {
      elem.remove();
    });

    Popup.showCustom({
      size: PopupSize.SMALL,
      style: PopupStyle.ERROR,
      title,
      contents: [
        {
          type: ContentType.TEXT,
          content: text,
        },
        {
          type: ContentType.TEXT,
          content: isShowSupport ? `Если ошибка повторится, пожалуйста, обратитесь в техподдержку.` : ``,
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `OK`,
              onClickHandler: () => {},
              isClose: true
            },
          ],
        }
      ]
    });
  }
}
