import {FileType} from "../../const";
import {NTR} from "../../name-to-request";
import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import {getFormatFile, getTitlePhonogram, isEmptyObject, isTT, parseColor} from "../../utils/common";
import {definePhonogramStatus} from "../../utils/phonogram";

export default class PerformanceParsing {
  static groupIdData(response) {
    return response[NTR.GROUP_ID];
  }

  static performanceId(response) {
    return response[NTR.PERFORMANCE_ID];
  }

  static linkId(response) {
    return response[NTR.LINK_ID];
  }

  static link(response) {
    return {
      link: response.link,
      preview: response.preview || ``,
    };
  }

  static performanceIds(response) {
    const performanceId = response[NTR.PERFORMANCE_ID];

    return performanceId ? performanceId.split(`,`) : [];
  }

  static performanceTitle(data) {
    const performanceTitle = data.performance_title;

    if (!performanceTitle || performanceTitle === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return performanceTitle;
  }

  static performanceCid(data) {
    const performanceCid = data.performance_cid;

    return (!performanceCid || performanceCid === NTR.NO_ACCESS) ? NTR.EMPTY : performanceCid;
  }

  static unblock(data) {
    const unblock = data.unblock;

    return !unblock || (unblock && unblock === NTR.NULL);
  }

  static unblockPaid(data) {
    // if (isTT()) {
    //   data.unblock = `99`;
    // }
    const unblock = data.unblock;

    if (!unblock || (unblock && unblock === NTR.NULL)) {
      return NTR.NULL;
    }

    return unblock;
  }

  static performanceTurnId(data) {
    const performanceTurnId = data.performance_turn_id;

    return (!performanceTurnId || performanceTurnId === NTR.NO_ACCESS) ? NTR.EMPTY : performanceTurnId;
  }

  static performanceGroup(data) {
    const performanceGroup = data.performance_group;

    return (!performanceGroup || performanceGroup === NTR.NO_ACCESS) ? NTR.EMPTY : performanceGroup;
  }

  static performanceTimeStart(data) {
    const timeStart = data.performance_time_start;

    return (!timeStart || timeStart === NTR.NO_ACCESS) ? NTR.EMPTY : timeStart;
  }

  static performanceEntryBefore(data) {
    const performanceEntry = data.performance_entry;

    return (!performanceEntry || performanceEntry === NTR.NO_ACCESS || !performanceEntry.before || performanceEntry.before === NTR.NO_ACCESS) ? NTR.EMPTY : performanceEntry.before;
  }

  static performanceEntryAfter(data) {
    const performanceEntry = data.performance_entry;

    return (!performanceEntry || performanceEntry === NTR.NO_ACCESS || !performanceEntry.after || performanceEntry.after === NTR.NO_ACCESS) ? NTR.EMPTY : performanceEntry.after;
  }

  static performanceAgeCategory(data) {
    const ageCategory = data.performance_age_category;

    if (!ageCategory || ageCategory === NTR.NO_ACCESS) {
      return {
        ageCategoryId: NTR.EMPTY,
        title: NTR.EMPTY,
        color: NTR.EMPTY,
      };
    }

    return {
      ageCategoryId: PerformanceParsing.performanceAgeCategoryId(ageCategory),
      title: PerformanceParsing.performanceAgeCategoryTitle(ageCategory),
      color: PerformanceParsing.performanceAgeCategoryColor(ageCategory),
    };
  }

  static performanceAgeCategoryId(ageCategory) {
    const ageCategoryId = ageCategory.age_category_id;

    if (!ageCategoryId || ageCategoryId === `null` || ageCategoryId === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return ageCategoryId;
  }

  static performanceAgeCategoryTitle(ageCategory) {
    const ageCategoryTitle = ageCategory.age_category_title;

    if (!ageCategoryTitle || ageCategoryTitle.indexOf(`н/д`) >= 0 || ageCategoryTitle === `null` || ageCategoryTitle === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return ageCategoryTitle;
  }

  static performanceAgeCategoryColor(ageCategory) {
    const ageCategoryColor = ageCategory.age_category_color;

    if (!ageCategoryColor || ageCategoryColor === `null` || ageCategoryColor === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return parseColor(ageCategoryColor);
  }

  static performanceNomination(data) {
    const nomination = data.performance_nomination;

    if (!nomination || nomination === NTR.NO_ACCESS) {
      return {
        nominationId: NTR.EMPTY,
        title: NTR.EMPTY,
        color: NTR.EMPTY,
      };
    }

    return {
      nominationId: PerformanceParsing.performanceNominationId(nomination),
      title: PerformanceParsing.performanceNominationTitle(nomination),
      color: PerformanceParsing.performanceNominationColor(nomination),
    };
  }

  static performanceNominationId(nomination) {
    const nominationId = nomination.nomination_id;

    if (!nominationId || nominationId === `null` || nominationId === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return nominationId;
  }

  static performanceNominationTitle(nomination) {
    const nominationTitle = nomination.nomination_title;

    if (!nominationTitle || nominationTitle.indexOf(`н/д`) >= 0 || nominationTitle === `null` || nominationTitle === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return nominationTitle;
  }

  static performanceNominationColor(nomination) {
    const nominationColor = nomination.nomination_color;

    if (!nominationColor || nominationColor === `null` || nominationColor === NTR.NO_ACCESS) {
      return NTR.EMPTY;
    }

    return parseColor(nominationColor);
  }

  static performanceQualification(data) {
    const performanceQualification = data.performance_qualification;

    if (!performanceQualification || performanceQualification === NTR.NO_ACCESS || !performanceQualification.qualification_id || !performanceQualification.qualification_id === null) {
      return {
        qualificationId: NTR.EMPTY,
        title: NTR.EMPTY,
        color: NTR.EMPTY,
      };
    }

    return {
      qualificationId: performanceQualification.qualification_id,
      title: performanceQualification.qualification_title.indexOf(`н/д`) >= 0 ? NTR.EMPTY : performanceQualification.qualification_title,
      color: parseColor(performanceQualification.qualification_color),
    };
  }

  static performanceTimeFull(data) {
    const performanceTimeFull = data.performance_time_full;

    if (!performanceTimeFull || performanceTimeFull === NTR.NO_ACCESS || performanceTimeFull === `0`) {
      return NTR.NULL;
    }

    return performanceTimeFull;
  }

  static performanceMoney(data) {
    const performanceMoney = data.performance_money;

    if (!performanceMoney || performanceMoney === NTR.NO_ACCESS) {
      return {
        status: `0`,
        toPay: 0,
        paid: 0,
      };
    }

    return {
      status: (!performanceMoney.money_status || performanceMoney.money_status === null) ? `0` : performanceMoney.money_status,
      toPay: (!performanceMoney.amount_to_pay || performanceMoney.amount_to_pay === 0 || performanceMoney.amount_to_pay === null) ? 0 : +performanceMoney.amount_to_pay,
      paid: (!performanceMoney.amount_paid || performanceMoney.amount_paid === 0 || performanceMoney.amount_paid === null) ? 0 : +performanceMoney.amount_paid,
    };
  }

  static performanceArchive(data) {
    const archive = data.performance_archive;

    if (!archive || archive === NTR.NO_ACCESS) {
      return false;
    }

    return archive === NTR.ONE;
  }

  static performanceCity(data) {
    const city = data.performance_city;

    return (!city || city === NTR.NO_ACCESS || city.indexOf(`н/д`) >= 0) ? NTR.EMPTY : city;
  }

  static performanceCityFull(data) {
    const city = data.performance_city_full;

    return (!city || city === NTR.NO_ACCESS || city.indexOf(`н/д`) >= 0) ? NTR.EMPTY : city;
  }

  static performanceCityId(data) {
    const cityId = data.performance_city_id;

    return (!cityId || cityId === NTR.NO_ACCESS || cityId === null) ? NTR.EMPTY : cityId;
  }

  static performanceCityType(data) {
    const type = data.performance_city_type;

    return (!type || type === NTR.NO_ACCESS || type === null) ? NTR.EMPTY : type;
  }

  static performanceCityStype(data) {
    const type = data.performance_city_stype;

    return (!type || type === NTR.NO_ACCESS || type === null) ? NTR.EMPTY : type;
  }

  static performanceCountry(data) {
    const country = data.performance_country;
    return (!country || country === NTR.NO_ACCESS || country === null || country.indexOf(`н/д`) >= 0) ? NTR.EMPTY : country;
  }

  static performanceTeam(data) {
    const teamId = PerformanceParsing.performanceTeamId(data);
    return {
      teamId,
      title: PerformanceParsing.performanceTeamTitle(data),
      city: PerformanceParsing.performanceTeamCity(data, teamId),
      cityId: ``,
      cityType: PerformanceParsing.performanceTeamCityType(data),
      cityShortType: PerformanceParsing.performanceTeamCityStype(data),
      isCityPrint: PerformanceParsing.teamCityPrint(data),
      isRepresent: data.performance_team_id === `0`,
      aliases: [],
    };
  }

  static performanceTeamId(data) {
    const performanceTeamId = data.performance_team_id;

    return (!performanceTeamId || performanceTeamId === NTR.NO_ACCESS) ? NTR.EMPTY : performanceTeamId;
  }

  static performanceTeamTitle(data) {
    const performanceTeamTitle = data.performance_team_title;

    return (!performanceTeamTitle || performanceTeamTitle === NTR.NO_ACCESS || performanceTeamTitle.indexOf(`н/д`) >= 0 || performanceTeamTitle === null) ? NTR.EMPTY : performanceTeamTitle;
  }

  static performanceTeamCity(data, teamId) {
    const performanceTeamCity = data.performance_team_city;

    return (!performanceTeamCity
      || performanceTeamCity === NTR.NO_ACCESS
      || performanceTeamCity.indexOf(`н/д`) >= 0
      || performanceTeamCity === null
      || !teamId || teamId === `0`) ? NTR.EMPTY : performanceTeamCity;
  }

  static performanceTeamCityType(data) {
    const type = data.performance_team_city_type;

    return (!type || type === NTR.NO_ACCESS || type === null) ? NTR.EMPTY : type;
  }

  static performanceTeamCityStype(data) {
    const type = data.performance_team_city_stype;

    return (!type || type === NTR.NO_ACCESS || type === null) ? NTR.EMPTY : type;
  }

  static teamCityPrint(data) {
    const teamCityPrint = data.team_city_print;

    return !!teamCityPrint && teamCityPrint !== NTR.NO_ACCESS && teamCityPrint === NTR.ONE;
  }

  static performanceOrganization(data) {
    const organizationId = PerformanceParsing.performanceOrganizationId(data);
    return {
      organizationId,
      title: PerformanceParsing.performanceOrganizationTitle(data),
      city: PerformanceParsing.performanceOrganizationCity(data, organizationId),
      cityId: ``,
      cityType: PerformanceParsing.performanceOrganizationCityType(data),
      cityShortType: PerformanceParsing.performanceOrganizationCityStype(data),
      isCityPrint: PerformanceParsing.organizationCityPrint(data),
      isRepresent: data.performance_organization_id === `0`,
      aliases: [],
    };
  }

  static performanceOrganizationId(data) {
    const performanceOrganizationId = data.performance_organization_id;

    return (!performanceOrganizationId || performanceOrganizationId === NTR.NO_ACCESS) ? NTR.EMPTY : performanceOrganizationId;
  }

  static performanceOrganizationTitle(data) {
    const performanceOrganizationTitle = data.performance_organization_title;

    return (!performanceOrganizationTitle || performanceOrganizationTitle === NTR.NO_ACCESS || performanceOrganizationTitle.indexOf(`н/д`) >= 0 || performanceOrganizationTitle === null) ? NTR.EMPTY : performanceOrganizationTitle;
  }

  static performanceOrganizationCity(data, organizationId) {
    const performanceOrganizationCity = data.performance_organization_city;

    return (!performanceOrganizationCity
      || performanceOrganizationCity === NTR.NO_ACCESS
      || performanceOrganizationCity.indexOf(`н/д`) >= 0
      || performanceOrganizationCity === null
      || !organizationId || organizationId === `0`) ? NTR.EMPTY : performanceOrganizationCity;
  }

  static performanceOrganizationCityType(data) {
    const type = data.performance_organization_city_type;

    return (!type || type === NTR.NO_ACCESS || type === null) ? NTR.EMPTY : type;
  }

  static performanceOrganizationCityStype(data) {
    const type = data.performance_organization_city_stype;

    return (!type || type === NTR.NO_ACCESS || type === null) ? NTR.EMPTY : type;
  }

  static organizationCityPrint(data) {
    const organizationCityPrint = data.organization_city_print;

    return !!organizationCityPrint && organizationCityPrint !== NTR.NO_ACCESS && organizationCityPrint === NTR.ONE;
  }

  // static performanceDirector(data) { // новая структура ответа от сервера 11/12/2022
  //   const performanceDirector = data.performance_director;

  //   if (!performanceDirector || performanceDirector === NTR.NO_ACCESS) {
  //     return [];
  //   }

  //   const directorIds = Object.keys(performanceDirector);

  //   const directors = directorIds.map((directorId) => {
  //     const director = performanceDirector[directorId].split(`,`);
  //     const name = parseNameTypeBasic(director[0].trim().split(` `));

  //     return {
  //       directorId,
  //       surname: name.surname,
  //       lastname: name.lastname,
  //       middlename: name.middlename,
  //       title: director[1].replace(/\s+/g, ` `).trim(),
  //       peopleId: director[2].replace(/\s+/g, ` `).trim(),
  //       aliases: [],
  //       phones: [],
  //       emails: [],
  //       type: `руководитель`,
  //     };
  //   });

  //   const getPeople = ({peopleId, surname, lastname, middlename, aliases, phones, emails, type}) => {
  //     return {
  //       peopleId,
  //       surname,
  //       lastname,
  //       middlename,
  //       aliases,
  //       phones,
  //       emails,
  //       type,
  //     };
  //   };

  //   const getPosition = ({directorId, title}) => {
  //     return {
  //       directorId,
  //       title
  //     };
  //   };

  //   const peoples = [];

  //   directors.forEach((director) => {
  //     if (!peoples.some((people) => people.peopleId === director.peopleId)) {
  //       peoples.push(getPeople(director));
  //     }
  //   });

  //   peoples.forEach((people) => {
  //     people.positions = [];
  //     directors.forEach((director) => {
  //       if (people.peopleId === director.peopleId) {
  //         people.positions.push(getPosition(director));
  //       }
  //     });
  //   });

  //   return peoples;
  // }

  static performanceDirectorNew(data) {
    const performanceDirector = data.performance_director;

    if (!performanceDirector || isEmptyObject(performanceDirector) || performanceDirector === NTR.NO_ACCESS) {
      return [];
    }

    const sortDirectorId = (a, b) => {
      if (isEmptyArray(a.positions) || isEmptyArray(b.positions)) {
        return 1;
      }
      return +a.positions[0].directorId - +b.positions[0].directorId;
    };

    return Object.keys(performanceDirector).map((peopleId) => {
      const director = performanceDirector[peopleId];
      return {
        peopleId,
        surname: director.surname,
        lastname: director.lastname,
        middlename: director.middlename,
        aliases: [],
        phones: [],
        emails: [],
        type: `руководитель`,
        person: director.person || ``,
        positions: PerformanceParsing.directorTitle(director.director_title),
      };
    })
    .sort((a, b) => sortDirectorId(a, b));
  }

  static directorTitle(directorTitle) {
    return Object.keys(directorTitle)
      .map((directorId) => {
        return {
          directorId,
          title: directorTitle[directorId]
        };
      });
  }

  static performancePlayer(data) {
    const performancePlayer = data.performance_player;

    if (!performancePlayer || performancePlayer === NTR.NO_ACCESS) {
      return [];
    }

    const playerIds = Object.keys(performancePlayer);

    return playerIds.map((playerId) => {
      const player = performancePlayer[playerId];
      return {
        playerId,
        peopleId: player.people_id || ``,
        surname: player.surname || ``,
        lastname: player.lastname || ``,
        middlename: player.middlename || ``,
        title: player.player_title || ``,
        type: `участник`,
        aliases: [],
        phones: [],
        emails: [],
      };
    });
  }

  static performancePlayerCount(data) {
    const performancePlayerCount = data.performance_player_count;

    return (!performancePlayerCount || performancePlayerCount === NTR.NO_ACCESS) ? NTR.NULL : +performancePlayerCount;
  }

  static playerCountNameShow(data) {
    const countName = data.player_count_name_show;
    return countName && countName === NTR.ONE;
  }

  static performanceContact(data) {
    const performanceContact = data.performance_contact;

    if (!performanceContact || isEmptyObject(performanceContact) || performanceContact === NTR.NO_ACCESS) {
      return null;
    }

    const peopleIds = Object.keys(performanceContact);
    const people = performanceContact[peopleIds[0]];

    return {
      peopleId: peopleIds[0],
      surname: people.surname,
      lastname: people.lastname,
      middlename: people.middlename,
      emails: [],
      phones: [],
      type: `контактное лицо`,
    };
  }

  static phonogramsData(data) {
    const phonograms = PerformanceParsing.performancePhonograms(data);
    const generalPhonogramStatus = PerformanceParsing.phonogramGeneralStatus(data);
    const isRequiredPhonogram = PerformanceParsing.isRequiredPhonogram(generalPhonogramStatus);

    return {
      phonograms,
      generalPhonogramStatus,
      isRequiredPhonogram
    };
  }

  static performancePhonograms(data) {
    const phonogramTitle = data.phonogram_title;

    if (!phonogramTitle || phonogramTitle === NTR.NO_ACCESS) {
      return [];
    }

    const phonogramIds = Object.keys(phonogramTitle);

    return phonogramIds.map((phonogramId) => {
      return {
        id: phonogramId,
        phonogramId,
        title: PerformanceParsing.phonogramTitle(data, phonogramId),
        format: PerformanceParsing.phonogramFormat(data, phonogramId),
        link: PerformanceParsing.phonogramLink(data, phonogramId),
        size: PerformanceParsing.phonogramSize(data, phonogramId),
        date: PerformanceParsing.phonogramDate(data, phonogramId),
        startPoint: PerformanceParsing.phonogramStartWithPoint(data, phonogramId),
        duration: PerformanceParsing.phonogramDuration(data, phonogramId),
        status: PerformanceParsing.performancePhonogramStatus(data, phonogramId),
      };
    });
  }

  static phonogramTitle(data, phonogramId) {
    const phonogramTitle = data.phonogram_title;

    return (!phonogramTitle || phonogramTitle === NTR.NO_ACCESS || !phonogramTitle[phonogramId]) ? `Фонограмма` : getTitlePhonogram(phonogramTitle[phonogramId]);
  }

  static phonogramFormat(data, phonogramId) {
    const phonogramTitle = data.phonogram_title;

    return (!phonogramTitle || phonogramTitle === NTR.NO_ACCESS || !phonogramTitle[phonogramId]) ? `` : getFormatFile(phonogramTitle[phonogramId]);
  }

  static phonogramLink(data, phonogramId) {
    const phonogramLink = data.phonogram_link;

    return (!phonogramLink || phonogramLink === NTR.NO_ACCESS || !phonogramLink[phonogramId]) ? `` : phonogramLink[phonogramId];
  }

  static phonogramSize(data, phonogramId) {
    const phonogramSize = data.phonogram_size;

    return (!phonogramSize || phonogramSize === NTR.NO_ACCESS || !phonogramSize[phonogramId]) ? `` : +phonogramSize[phonogramId];
  }

  static phonogramDate(data, phonogramId) {
    const phonogramDate = data.phonogram_date;

    return (!phonogramDate || phonogramDate === NTR.NO_ACCESS || !phonogramDate[phonogramId]) ? `` : phonogramDate[phonogramId]; // timeConverter(json.phonogram_date[id], "day:month:year")
  }

  static phonogramStartWithPoint(data, phonogramId) {
    const phonogramStartWithPoint = data.phonogram_start_with_point;

    if (!phonogramStartWithPoint || phonogramStartWithPoint === NTR.NO_ACCESS || !phonogramStartWithPoint[phonogramId]) {
      return {
        name: `Не указано`,
        id: `null`
      };
    }

    switch (phonogramStartWithPoint[phonogramId]) {
      case null:
        return {
          name: `Не указано`,
          id: `null`
        };
      case `null`:
        return {
          name: `Не указано`,
          id: `null`
        };
      case `0`:
        return {
          name: `из кулис`,
          id: `0`
        };
      case `1`:
        return {
          name: `с точки`,
          id: `1`
        };
      default:
        return {
          name: `Не указано`,
          id: `null`
        };
    }
  }

  static phonogramDuration(data, phonogramId) {
    const phonogramDuration = data.phonogram_duration;

    return (!phonogramDuration || phonogramDuration === NTR.NO_ACCESS || !phonogramDuration[phonogramId]) ? NTR.NULL : +phonogramDuration[phonogramId]; // timeConverter(json.phonogram_date[id], "day:month:year")
  }

  static performancePhonogramStatus(data, phonogramId) {
    const performancePhonogramStatus = data.performance_phonogram_status;

    if (!performancePhonogramStatus || performancePhonogramStatus === NTR.NO_ACCESS || !performancePhonogramStatus[phonogramId]) {
      return ``;
    }

    if (isTT()) {
      return {name: `Удалена`, id: `-1`, info: `base`};
    }

    return definePhonogramStatus(performancePhonogramStatus[phonogramId]);
  }

  static phonogramGeneralStatus(data) {
    const status = data.performance_phonogram_status;

    if (!status) {
      return {};
    }

    if (isEmptyObject(status)) {
      return {
        [NTR.EMPTY]: null
      };
    }

    const keys = Object.keys(status);

    if (keys.length === 1) {
      return {
        [keys[0]]: status[keys[0]]
      };
    }

    const index = keys.findIndex((it) => it === ``);
    if (index === -1) {
      return {
        [keys[0]]: status[keys[0]]
      };
    }

    return {
      [keys[index]]: status[keys[index]]
    };
  }

  static isRequiredPhonogram(generalPhonogramStatus) {
    const key = Object.keys(generalPhonogramStatus)[0];

    return key === NTR.EMPTY && String(generalPhonogramStatus[key]) === `1000`;
  }

  static performanceContent(data) {
    const performanceContent = data.performance_content;

    if (!performanceContent || performanceContent === NTR.NO_ACCESS) {
      return [];
    }

    const links = performanceContent
      .filter((content) => content.link)
      .map((content) => {
        return {
          linkId: content.link_id || ``,
          link: content.link || ``,
          preview: (content.preview && content.preview !== `--`) ? content.preview : ``,
          type: FileType.LINK,
          label: ``,
        };
      });

    const videos = performanceContent
      .filter((content) => content.file)
      .filter((content) => {
        const format = getFormatFile(content.file);
        return format === `mp4`;
      })
      .map((content) => {
        return {
          fileId: content.file_id || ``,
          isArchive: String(content.file_status) !== NTR.ONE,
          source: content.file || ``,
          type: FileType.VIDEO,
          label: ``,

        };
      });

    const images = performanceContent
      .filter((content) => content.file)
      .filter((content) => {
        const format = getFormatFile(content.file);
        return format === `jpg` || format === `jpeg` || format === `png`;
      })
      .map((content) => {
        return {
          fileId: content.file_id || ``,
          isArchive: String(content.file_status) !== NTR.ONE,
          source: content.file || ``,
          type: FileType.IMAGE,
          label: ``,
        };
      });

    const pdfes = performanceContent
      .filter((content) => content.file)
      .filter((content) => {
        const format = getFormatFile(content.file);
        return format === `pdf`;
      })
      .map((content) => {
        return {
          fileId: content.file_id || ``,
          isArchive: String(content.file_status) !== NTR.ONE,
          source: content.file || ``,
          type: FileType.PDF,
          label: ``,
        };
      });

    const unknown = performanceContent
      .filter((content) => content.file)
      .filter((content) => {
        const format = getFormatFile(content.file);
        return format !== `jpg` && format !== `jpeg` && format !== `png` && format !== `mp4` && format !== `pdf`;
      })
      .map((content) => {
        return {
          fileId: content.file_id || ``,
          isArchive: String(content.file_status) !== NTR.ONE,
          source: content.file || ``,
          type: FileType.UNKNOWN,
          label: ``,
        };
      });


    return [].concat(links, videos, images, pdfes, unknown);
  }

  static performanceLink(data) {
    const performanceLink = data.performance_link;

    if (!performanceLink || performanceLink === NTR.NO_ACCESS) {
      return [];
    }

    return performanceLink.map((item) => {
      return {
        linkId: item.link_id,
        source: item.source
      };
    });
  }

  static performanceRating(data) { // врменный парсинг, чтоб пока дальше не переделывать
    const performanceRating = data.performance_rating;

    // 189080498: {22: "9.0"}

    if (!performanceRating || performanceRating === NTR.NO_ACCESS || performanceRating === NTR.NOT_REQUIRED) {
      return [];
    }

    const result = [];

    const refereeIds = Object.keys(performanceRating);

    refereeIds.forEach((refereeId) => {
      const rating = performanceRating[refereeId];
      if (rating && rating !== NTR.NOT_REQUIRED) {
        const criteriaIds = Object.keys(rating);
        criteriaIds.map((criteriaId) => {
          const ratingData = rating[criteriaId];
          result.push(
              {
                criteriaId,
                rating: ratingData === `-` ? `` : ratingData,
              }
          );
        });
      }

    });

    return result;
  }

  // static performanceRating(data) {
  //   const performanceRating = data.performance_rating;

  //   // 189080498: {22: "9.0"}

  //   if (!performanceRating || performanceRating === NTR.NOT_REQUIRED || performanceRating === NTR.NO_ACCESS) {
  //     return [];
  //   }

  //   const criteriaIds = Object.keys(performanceRating);

  //   return criteriaIds.map((criteriaId) => {
  //     const ratingData = performanceRating[criteriaId];
  //     return {
  //       criteriaId,
  //       rating: ratingData === `-` ? `` : ratingData,
  //     };
  //   });
  // }

  static performanceAllRating(data) {
    const rating = data.performance_rating;

    return (rating && rating !== NTR.NOT_REQUIRED && rating !== NTR.NO_ACCESS) ? rating : {};
  }

  static performanceRatingSum(data) {
    const rating = data.performance_rating_sum;

    return (rating && rating !== NTR.NO_ACCESS) ? rating : NTR.EMPTY;
  }

  static refereePerformanceRatingSum(data) {
    const rating = data.referee_performance_rating_sum;

    if (!rating || rating === NTR.NO_ACCESS || rating === `null` || rating === null) {
      return [];
    }

    const refereeIds = Object.keys(rating);

    return refereeIds.map((refereeId) => {
      let sum = ``;
      if (rating[refereeId] === `not all criteria`) {
        sum = `?`;
      } else if (rating[refereeId] === `null` || rating[refereeId] === null) {
        sum = `–`;
      } else {
        sum = +rating[refereeId];
      }

      return {
        refereeId,
        sum,
      };
    });
  }

  static performancePrize(data) {
    const prize = data.performance_prize;

    return (prize && prize !== NTR.NO_ACCESS) ? prize : NTR.EMPTY;
  }

  static performancePrizeManual(data) {
    const prize = data.performance_prize_manual;

    return (prize && prize !== NTR.NO_ACCESS) ? prize : NTR.EMPTY;
  }

  static documentPrinted(data) {
    const printed = data.document_printed;

    if (!printed || printed === `null` || printed === NTR.NO_ACCESS) {
      return [];
    }

    return printed.map((print) => {
      return {
        printId: String(print.print_id),
        isPrinted: String(print.date_print) !== NTR.ZERO,
        // isPrinted: print.print_id !== NTR.ZERO,
        date: print.date_print,
      };
    });
  }

  static performanceNote(data) {
    const performanceNote = data.performance_note;

    if (!performanceNote || performanceNote === NTR.NO_ACCESS) {
      return [];
    }

    const noteIds = Object.keys(performanceNote);

    const notes = noteIds.map((noteId) => {
      const note = performanceNote[noteId];

      return {
        noteId,
        text: note.note || ``,
        date: note.time,
        authorId: note.author_id,
        authorType: note.author_type,
        isAccess: true,
      };
    });

    return notes.sort((a, b) => b.date - a.date);
  }

  static performanceUserData(data) {
    const userData = data.performance_user_data;

    return (!userData || userData === NTR.NO_ACCESS || isEmptyObject(userData)) ? {} : userData;
  }

  static performanceReal(data) {
    const start = data.performance_real_start;
    const finish = data.performance_real_stop;

    return {
      start: (start && start !== NTR.NO_ACCESS) ? start : ``,
      finish: (finish && finish !== NTR.NO_ACCESS) ? finish : ``
    };
  }

  static performanceAddPeople(data) {
    const addPeople = data.performance_add_people;

    if (!addPeople || addPeople === NTR.NO_ACCESS) {
      return null;
    }

    return {
      peopleId: addPeople,
      type: `регистратор`,
      surname: ``,
      lastname: ``,
      middlename: ``,
      aliases: [],
      phones: [],
      emails: [],
    };
  }

  static performanceCreationDate(data) {
    const date = data.performance_creation_date;

    if (!date || date === NTR.NO_ACCESS) {
      return null;
    }

    return date;
  }

  static performanceSource(data) {
    const performanceSource = data.performance_source;

    if (!performanceSource || performanceSource === `0`) {
      return {
        id: ``,
        source: ``,
        sourceId: ``
      };
    }

    if (performanceSource.indexOf(`g`) === 0) {
      return {
        id: performanceSource,
        source: `group`,
        sourceId: performanceSource.slice(1)
      };
    }

    if (performanceSource.indexOf(`lm`) === 0) {
      return {
        id: performanceSource,
        source: `landmark`,
        sourceId: performanceSource.slice(2)
      };
    }

    return {
      id: performanceSource,
      source: `performance`,
      sourceId: performanceSource
    };
  }

  static contestRole(data) {
    const contestRole = data.contest_role;

    if (!contestRole || contestRole === NTR.NO_ACCESS) {
      return [];
    }

    return contestRole.split(`,`);
  }

  static contestId(data) {
    const contestId = data.contest_id;

    if (!contestId || contestId === NTR.NO_ACCESS) {
      return ``;
    }

    return contestId;
  }

  // performance_referee: {count: null, done: "1"}
  static performanceReferee(data) {
    const refereeCount = data.performance_referee;

    if (!refereeCount || refereeCount === NTR.NO_ACCESS) {
      return {
        isReferee: true,
        done: +NTR.ZERO,
        all: +NTR.ZERO
      };
    }

    if (refereeCount.count === null || refereeCount.count === `null`) {
      return {
        isReferee: false,
        done: +refereeCount.done || +NTR.ZERO,
        all: +refereeCount.count || +NTR.ZERO,
      };
    }

    return {
      isReferee: true,
      done: +refereeCount.done || +NTR.ZERO,
      all: +refereeCount.count || +NTR.ZERO,
    };
  }
}
