import {ButtonTitle} from "../../const";
import Popup, {ContentType, PopupSize} from "../../static/popup/popup";

export const confirmSetRegistrationLangPopup = (name = ``, handler) => {
  Popup.showCustom({
    size: PopupSize.SMALL,
    title: `Обновление настроек формы регистрации`,
    contents: [
      {
        type: ContentType.TEXT,
        content: `Текущие настройки формы регистрации будут заменены на <b>${name}</b>.<br>Хотите продолжить?`,
      },
      {
        type: ContentType.BUTTONS,
        content: [
          {
            title: ButtonTitle.CANCEL,
            isClose: true,
          },
          {
            title: ButtonTitle.PROCEED,
            onClickHandler: handler,
            isClose: true,
          },
        ],
      }
    ]
  });
};
